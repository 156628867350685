import React, { useState } from 'react'
import { Button, Divider, Descriptions, Collapse } from 'antd'
import Modal from '@components/modals/index'
import ParticipantContactDetailForm from '@components/forms/contactDetails'
import ParticipantSummaryForm from '@components/forms/summaryDetails'
import useModal from '@src/hooks/useModal'
import PrevParticipantContactTable from '@components/table'
import classNames from 'classnames'
import moment from 'moment'

const { Panel } = Collapse

export default function ParticipantContactContainer(props) {
  const {
    updateParticipantDetail,
    setParticipantContactDetail,
    participantToDetails,
    user,
    parentComponent
  } = props
  const { visible, handleVisibility } = useModal()
  const [participantDetailSaved, setParticipantDetailSaved] = useState(false)
  const [participantContactDetailSaved, setParticipantContactDetailSaved] = useState(
    false
  )
  const [contactClick, setContactClick] = useState(false)

  const columns = [
    { title: 'Address', dataIndex: 'address', key: 'address' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Suburb', dataIndex: 'suburb', key: 'suburb' },
    { title: 'Post Code', dataIndex: 'postalCode', key: 'postalCode' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Mobile #', dataIndex: 'mobileNo', key: 'mobileNo' },
    { title: 'Phone #', dataIndex: 'phoneNo', key: 'phoneNo' },
    { title: 'Work Phone #', dataIndex: 'workPhoneNo', key: 'workPhoneNo' }
  ]

  const handleModalVisible = e => {
    e.currentTarget.id === 'ParticipantContactEdit'
      ? setContactClick(true)
      : setContactClick(false)
    handleVisibility(true)
  }

  const handleCreateParticipantDetail = async form => {
    const formData = { ...form, participantId: participantToDetails.id }
    setParticipantContactDetail(formData)
      .then(() => setParticipantContactDetailSaved(true))
      .catch(() => setParticipantContactDetailSaved(false))
    handleVisibility(false)
  }

  const handleEditSummary = async form => {
    const formData = { ...form, participantId: participantToDetails.id }
    updateParticipantDetail(formData, parentComponent)
      .then(() => setParticipantDetailSaved(true))
      .catch(() => setParticipantDetailSaved(false))
    handleVisibility(false)
  }

  return (
    <div>
      <Divider orientation="left"> Participants Details:</Divider>
      <Descriptions>
        <Descriptions.Item label="First Name">
          {participantToDetails.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {participantToDetails.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date Of Birth">
          {moment(participantToDetails.dob).format('DD-MM-YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {participantToDetails.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Gender Description">
          {participantToDetails.genderDescription}
        </Descriptions.Item>
        <Descriptions.Item label="Participant Status">
          <span
            className={classNames(
              'participant-badge',
              'recruitmentStatus-' + `${participantToDetails.recruitmentStatus}`
            )}
          >
            {participantToDetails.recruitmentStatus}{' '}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Participant Recruitment Status" span={2}>
          <span
            className={classNames(
              'participant-badge',
              'ParticipantRecruitmentStatus-' +
                `${participantToDetails.participantRecruitmentStatus}`
            )}
          >
            {' '}
            {participantToDetails.participantRecruitmentStatus}{' '}
          </span>
        </Descriptions.Item>
        <Descriptions.Item></Descriptions.Item>
        <Descriptions.Item label="Parent Recruitment Status">
          <span
            className={classNames(
              'participant-badge',
              'ParentRecruitmentStatus-' +
                `${participantToDetails.parentRecruitmentStatus}`
            )}
          >
            {participantToDetails.parentRecruitmentStatus}{' '}
          </span>
        </Descriptions.Item>
      </Descriptions>
      <Button
        id="ParticipantDetailEdit"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Edit Details
      </Button>
      <Divider orientation="left">Participants Contact Details:</Divider>

      <Descriptions>
        <Descriptions.Item label="State">
          {participantToDetails?.currentContactDetails?.state}
        </Descriptions.Item>
        <Descriptions.Item label="Suburb">
          {participantToDetails?.currentContactDetails?.suburb}
        </Descriptions.Item>
        <Descriptions.Item label="Post Code">
          {participantToDetails?.currentContactDetails?.postalCode}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {participantToDetails?.currentContactDetails?.address}
        </Descriptions.Item>
        <Descriptions.Item label="Work Phone#">
          {participantToDetails?.currentContactDetails?.workPhoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="Phone#">
          {participantToDetails?.currentContactDetails?.phoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile #">
          {participantToDetails?.currentContactDetails?.mobileNo}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {participantToDetails?.currentContactDetails?.email}
        </Descriptions.Item>
      </Descriptions>
      <Button
        id="ParticipantContactEdit"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Edit a Contact
      </Button>
      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={1600}
        title={
          contactClick
            ? 'Edit A Contact Detail of Participant'
            : 'Edit Participant Details'
        }
        destroyOnClose
      >
        {contactClick ? (
          <ParticipantContactDetailForm
            data={participantToDetails}
            onContactDetailSubmit={handleCreateParticipantDetail}
          />
        ) : (
          <ParticipantSummaryForm
            data={participantToDetails}
            onParticipantDetailSubmit={handleEditSummary}
          />
        )}
      </Modal>
      {participantToDetails &&
      participantToDetails.previousContactDetails.length > 0 ? (
        <Collapse accordion>
          <Panel header="Show Previous Contact Details" key="1">
            <div>
              <Descriptions>
                <Descriptions.Item>
                  <div style={{ width: '1100px' }}>
                    <PrevParticipantContactTable
                      columns={columns}
                      data={participantToDetails?.previousContactDetails}
                      rowKey="id"
                      size="small"
                    />
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Panel>
        </Collapse>
      ) : (
        <div> </div>
      )}
    </div>
  )
}
