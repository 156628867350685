import React, { useEffect } from 'react'
import { Card, Col, Row, Descriptions } from 'antd'
import connect from 'react-redux/es/connect/connect'
import { getStats } from '@actions/dashboard'
import styled from 'styled-components'

const MainDiv = styled.div`
  font-weight: 700;
  font-size: 22px;
  background-color: #f4f4f4;
  color: #333333;
`

function DashboardContainer(props) {
  const {
    //State
    stats,
    //Dispatch
    getStats,
  } = props

  useEffect(() => {
    getStats()
  }, [getStats])

  const parentConsentSurvey = stats && stats.parentConsentSurvey
  const participantConsentSurvey = stats && stats.participantConsentSurvey
  const parentRecruitmentStatus = stats && stats.parentRecruitmentStatus
  const participantRecruitmentStatus = stats && stats.participantRecruitmentStatus
  const recruitmentStatus = stats && stats.recruitmentStatus
  const recallingSentenceSurvey = stats && stats.recallingSentenceSurvey
  const parentMp1Survey = stats && stats.parentMp1Survey
  const parentMp2Survey = stats && stats.parentMp2Survey
  const participantMp1Survey = stats && stats.participantMp1Survey
  const participantMp2Survey = stats && stats.participantMp2Survey

  return (
    <div>
      <MainDiv> ELVS Statistics </MainDiv>
      <div style={{ width: '400px', float: 'left' }}>
        <Col>
          <Card
            title="Participant Summary"
            headStyle={{
              backgroundColor: '#b8d5d5',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            bordered={false}
          >
            <Descriptions title="Overall" layout="vertical"></Descriptions>
            <Row>
              <Col span={12}>Active:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {recruitmentStatus?.active !== undefined
                  ? recruitmentStatus?.active
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Inactive:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {recruitmentStatus?.inactive !== undefined
                  ? recruitmentStatus?.inactive
                  : '0'}
              </Col>
            </Row>

            <div style={{ padding: '20px' }}></div>
            <Descriptions
              title="Parent Recruitment Status"
              layout="vertical"
            ></Descriptions>
            <Row>
              <Col span={12}>Not Eligible:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.NOT_ELIGIBLE !== undefined
                  ? parentRecruitmentStatus?.NOT_ELIGIBLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Eligible:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.ELIGIBLE !== undefined
                  ? parentRecruitmentStatus?.ELIGIBLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Un-Contactable:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.UNCONTACTABLE !== undefined
                  ? parentRecruitmentStatus?.UNCONTACTABLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Approached:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.APPROACHED !== undefined
                  ? parentRecruitmentStatus?.APPROACHED
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Did Not Respond:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.DID_NOT_RESPOND !== undefined
                  ? parentRecruitmentStatus?.DID_NOT_RESPOND
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Declined:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.DECLINED !== undefined
                  ? parentRecruitmentStatus?.DECLINED
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Recruited:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.RECRUITED !== undefined
                  ? parentRecruitmentStatus?.RECRUITED
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Withdrew:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.WITHDREW !== undefined
                  ? parentRecruitmentStatus?.WITHDREW
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Recruited Then Un-Contactable:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {parentRecruitmentStatus?.RECRUITED_THEN_UNCONTACTABLE !== undefined
                  ? parentRecruitmentStatus?.RECRUITED_THEN_UNCONTACTABLE
                  : '0'}
              </Col>
            </Row>
            <div style={{ padding: '20px' }}></div>
            <Descriptions
              title="Participant Recruitment Status"
              layout="vertical"
            ></Descriptions>
            <Row>
              <Col span={12}>Not Eligible:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.NOT_ELIGIBLE !== undefined
                  ? participantRecruitmentStatus?.NOT_ELIGIBLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Eligible:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.ELIGIBLE !== undefined
                  ? participantRecruitmentStatus?.ELIGIBLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Parent Consent Unknown:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.PARENT_CONSENT_UNKNOWN !== undefined
                  ? participantRecruitmentStatus?.PARENT_CONSENT_UNKNOWN
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Un-Contactable:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.UNCONTACTABLE !== undefined
                  ? participantRecruitmentStatus?.UNCONTACTABLE
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Approached:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.APPROACHED !== undefined
                  ? participantRecruitmentStatus?.APPROACHED
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Did Not Respond:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.DID_NOT_RESPOND !== undefined
                  ? participantRecruitmentStatus?.DID_NOT_RESPOND
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Declined By Parent:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.DECLINED_BY_PARENT !== undefined
                  ? participantRecruitmentStatus?.DECLINED_BY_PARENT
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Declined By Participant:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.DECLINED_BY_TEEN !== undefined
                  ? participantRecruitmentStatus?.DECLINED_BY_TEEN
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Recruited:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.RECRUITED !== undefined
                  ? participantRecruitmentStatus?.RECRUITED
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Withdrew By Parent:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.WITHDREW_BY_PARENT !== undefined
                  ? participantRecruitmentStatus?.WITHDREW_BY_PARENT
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Withdrew By Teen:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.WITHDREW_BY_TEEN !== undefined
                  ? participantRecruitmentStatus?.WITHDREW_BY_TEEN
                  : '0'}
              </Col>
            </Row>
            <Row>
              <Col span={12}>Recruited Then Un-Contactable:</Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {participantRecruitmentStatus?.RECRUITED_THEN_UNCONTACTABLE !==
                undefined
                  ? participantRecruitmentStatus?.RECRUITED_THEN_UNCONTACTABLE
                  : '0'}
              </Col>
            </Row>
          </Card>
        </Col>
      </div>
      <div className="flex-container" style={{ marginTop: '-30px' }}>
        <div>
          <Col>
            <Card
              title="Parent Consent Survey Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentConsentSurvey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentConsentSurvey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentConsentSurvey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          {' '}
          <Col>
            <Card
              title="Parent Measure Package 1 Survey Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentMp1Survey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentMp1Survey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {parentMp1Survey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          {' '}
          <Col>
            <Card
              title="Participant Consent Survey Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantConsentSurvey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantConsentSurvey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantConsentSurvey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>

        <div>
          {' '}
          <Col>
            <Card
              title="Participant Measure Package 1 Survey Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp1Survey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp1Survey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp1Survey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          {' '}
          <Col>
            <Card
              title="Recalling Sentences Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {recallingSentenceSurvey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {recallingSentenceSurvey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {recallingSentenceSurvey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
        <div>
          <Col>
            <Card
              title="Participant Measure Package 2 Survey Summary"
              headStyle={{
                backgroundColor: '#b8d5d5',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              bordered={false}
            >
              <Row>
                <Col span={12}>Total Invited:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp2Survey?.totalInvited}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Completed:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp2Survey?.completed}
                </Col>
              </Row>
              <Row>
                <Col span={12}>Incomplete:</Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  {participantMp2Survey?.incomplete}
                </Col>
              </Row>
            </Card>
          </Col>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ dashboard }) => ({
  stats: dashboard.stats,
  loading: dashboard.loading,
})

const mapDispatchToProps = {
  getStats,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
