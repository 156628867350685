import { combineReducers } from 'redux'
import user from '@reducers/user'
import combineReducer from '@reducers/combinedReducer'
// import parent from '@reducers/xparent'
import dashboard from '@reducers/dashboard'

// Add your new reducer here
const reducers = {
  user,
  dashboard,
  // parent,
  combineReducer
}

const rootReducer = combineReducers(reducers)

export default rootReducer
