import React, { useState } from 'react'
import { Button, Divider, Descriptions, Collapse } from 'antd'
import Modal from '@components/modals/index'
import SchoolForm from '@components/forms/schools'
import useModal from '@src/hooks/useModal'
import PrevSchoolDetailTable from '@components/table'

const { Panel } = Collapse

export default function SchoolSummaryContainer(props) {
  const { setSchoolDetail, participantToDetails, user, parentComponent } = props
  const { visible, handleVisibility } = useModal()
  const [schoolDetailSaved, setSchoolDetailSaved] = useState(false)
  const [schoolClick, setSchoolClick] = useState(false)

  let currentSchool = false
  if (participantToDetails.currentSchoolDetails !== null) {
    currentSchool = true
  }
  let prevSchoolDetail = false
  if (participantToDetails.previousSchoolDetails.length > 0) {
    prevSchoolDetail = true
  }
  const columns = [
    { title: 'School Name', dataIndex: 'name', key: 'name' },
    { title: 'Suburb', dataIndex: 'suburb', key: 'suburb' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Year Level', dataIndex: 'yearLevel', key: 'yearLevel' }
  ]
  const handleModalVisible = e => {
    e.currentTarget.id === 'SchoolDetail'
      ? setSchoolClick(true)
      : setSchoolClick(false)
    handleVisibility(true)
  }

  const handleCreateDetail = async form => {
    const formData = { ...form, participantId: participantToDetails.id }
    setSchoolDetail(formData, parentComponent)
      .then(() => setSchoolDetailSaved(true))
      .catch(() => setSchoolDetailSaved(false))
    handleVisibility(false)
  }

  return (
    <div>
      <Divider orientation="left">School Details 2020:</Divider>

      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={1600}
        title={schoolClick ? 'Edit A School Detail' : ''}
        destroyOnClose
      >
        {schoolClick ? (
          <SchoolForm
            data={
              participantToDetails.currentSchoolDetails !== null
                ? participantToDetails.currentSchoolDetails
                : prevSchoolDetail
                ? participantToDetails.previousSchoolDetails[
                    participantToDetails.previousSchoolDetails.length - 1
                  ]
                : null
            }
            onSchoolDetailSubmit={handleCreateDetail}
          />
        ) : (
          <div></div>
        )}
      </Modal>

      {currentSchool ? (
        <Descriptions>
          <Descriptions.Item label="School Name">
            {participantToDetails.currentSchoolDetails.name}
          </Descriptions.Item>
          <Descriptions.Item label="Suburb">
            {participantToDetails.currentSchoolDetails.suburb}
          </Descriptions.Item>
          <Descriptions.Item label="State">
            {participantToDetails.currentSchoolDetails.state}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {participantToDetails.currentSchoolDetails.status}
          </Descriptions.Item>
          <Descriptions.Item label="Year Level">
            {participantToDetails.currentSchoolDetails.yearLevel}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Descriptions>
          <Descriptions.Item label="School Name"> </Descriptions.Item>
          <Descriptions.Item label="Suburb"> </Descriptions.Item>
          <Descriptions.Item label="State"> </Descriptions.Item>
          <Descriptions.Item label="Status"> </Descriptions.Item>
          <Descriptions.Item label="Year Level"> </Descriptions.Item>
        </Descriptions>
      )}

      <Button
        id="SchoolDetail"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Edit
      </Button>

      {prevSchoolDetail ? (
        <Collapse accordion>
          <Panel header="Show Previous School Details" key="1">
            <Divider orientation="left">Previous School Details:</Divider>
            <Descriptions>
              <Descriptions.Item>
                <div style={{ width: '1100px' }}>
                  <PrevSchoolDetailTable
                    columns={columns}
                    data={participantToDetails.previousSchoolDetails}
                    rowKey="id"
                    size="small"
                  />
                </div>
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      ) : (
        <div> </div>
      )}
    </div>
  )
}
