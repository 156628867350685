import React, { useState } from 'react'
import { Button, Divider, Tooltip, Popconfirm} from 'antd'
import Modal from '@components/modals/index'
import useModal from '@src/hooks/useModal'
import moment from 'moment'
import CommentsTable from '@components/table'
import CommentForm from '@components/forms/comments'
import CommentEditForm from '@components/forms/comments/editComment'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

export default function CommentsContainer(props) {
  const { comments, setComment, updateComment, deleteComment, participantToDetails, user } = props
  const { visible, handleVisibility } = useModal()
  const [commentSaved, setCommentSaved] = useState(false)
  const [commentClick, setCommentClick] = useState(false)
  const [editedData, setEditedData] = useState({})

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 100,
      render: utcDate => {
        if (utcDate !== null) {
          return moment(utcDate).format('DD-MM-YYYY')
        } else {
          return <div align="center"> --- </div>
        }
      }
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: user => (
        <Tooltip title={user.email}>
          <span>{user.name}</span>
        </Tooltip>
      )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      render: (text, record) => (
        <span>
          <Tooltip title="Edit A Comment">
          <a
            id="CommentEdit"
            style={{ marginRight: 16 }}
            onClick={handleModalVisible}
          >
            <EditOutlined />
          </a>
          </Tooltip>
           <Divider type="vertical" />
           <Tooltip title="Delete A Comment">
             <Popconfirm title="Are You Sure to delete?" style={{ marginRight: 16, color: 'red'}} onConfirm={() => handleDeleteComment(record)}>
              <a style={{ marginRight: 16, color: 'red'}}><DeleteOutlined /></a>
            </Popconfirm>
          {/*<a*/}
          {/*  id="CommentDelete"*/}
          {/*  style={{ marginRight: 16, color:'red' }}*/}
          {/*  onClick={handleModalVisible}*/}
          {/*>*/}
          {/*  <DeleteOutlined />*/}
          {/*</a>*/}
          </Tooltip>
        </span>
      )
    }
  ]

  const handleModalVisible = e => {
    e.currentTarget.id === 'CommentAdd'
      ? setCommentClick(true)
      : setCommentClick(false)
    handleVisibility(true)
  }

  const handleDeleteComment = (comment) => {
    deleteComment(comment.id)
  }
  const handleCreate = async form => {
    const formData = {
      ...form,
      participantId: participantToDetails.id,
      userId: user.id
    }
    setComment(formData)
      .then(() => setCommentSaved(true))
      .catch(() => setCommentSaved(false))
    handleVisibility(false)
  }

  const handleEdit = async form => {
    updateComment(form)
      .then(() => setCommentSaved(true))
      .catch(() => setCommentSaved(false))
    handleVisibility(false)
  }

  return (
    <span>
      <Button
        id="CommentAdd"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Add
      </Button>
      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={200}
        title={commentClick ? 'Add A New Comment' : 'Edit A Comment'}
        destroyOnClose
      >
        {commentClick ? (
          <CommentForm data={comments} onCommentSubmit={handleCreate} />
        ) : (
          <CommentEditForm data={editedData} onUpdateCommentSubmit={handleEdit} />
        )}
      </Modal>
      <CommentsTable
        columns={columns}
        data={comments}
        rowKey="id"
        size="small"
        tableLayout="fixed"
        onRowClick={row => ({
          onClick: () => setEditedData(row)
        })}
      />
    </span>
  )
}
