import React from 'react'
import LoginForm from '@components/forms/login'
import style from './style.module.less'
import { connect } from 'react-redux'
import { loginUser } from '@actions/userActions'
import { Card, Typography } from 'antd'
const webpackVariables = require('webpackVariables')
const { Title } = Typography

function LoginPage(props) {
  const { dispatchOnLogin } = props

  const title = (
    <Title level={4} className="mb-0">
      Login
    </Title>
  )

  return (
    <div className={style.container}>
      <Card title={title} className={style.card}>
        <LoginForm onLogin={dispatchOnLogin} />
        {/*<span>*/}
        {/*  Or <Link to={ROUTES.register}>register </Link> now!*/}
        {/*</span>*/}
        <p style={{ textAlign: 'center' }}>
          <i>elvs version: {webpackVariables.VERSION}</i>
        </p>
      </Card>
    </div>
  )
}
const mapDispatchToProps = () => dispatch => {
  return {
    dispatchOnLogin: user => dispatch(loginUser(user))
  }
}
export default connect(null, mapDispatchToProps)(LoginPage)
