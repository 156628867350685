import { RECALLINGSENTENCE } from '@store/actionTypes'
import {
  fetchAllRecallingSentences,
  uploadConsent,
  downloadRecallingSentenceWorksheet,
  uploadCorrespondenceHistory,
  uploadRecallingSentenceDistribution,
  editSurveyStatus,
  deleteSurveyStatus
} from '@api/recallingSentence'
import { RECALLING_SENT_MSGS } from '@constants/strings'

import { message } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
/**
 * Get  Recalling Sentences
 */

export const getRecallingSentences = () => {
  return {
    type: RECALLINGSENTENCE.SET_RECALLING_SENTENCES,
    async payload() {
      const resp = await fetchAllRecallingSentences()
      return resp.data
    }
  }
}


/**
 * Get Recalling Sentence Task Worksheet Download
 */

export const getRecallingSentenceWorkSheetDownload = data => dispatch => {
  let payload
  data.length > 0
    ? (payload = {
      participantUuids: data
    })
    : (payload = {})

  const response = dispatch({
    type: RECALLINGSENTENCE.SET_RECALL_SENTENCE_DOWNLOAD_FILE,
    async payload() {
      const resp = await downloadRecallingSentenceWorksheet(
        'recallingSentence',
        payload
      )
      const fileName = resp.headers['content-disposition'].split('filename=')[1].replace(/['"]/g, '')
      const fileDownload = require('js-file-download')
      fileDownload(resp.data,
        fileName
      )
      return resp.data
    }
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
/**
 * Update uploaded Recalling Sentence Distribution file
 */
export const setRecallingSentenceDistributionHistory = data => dispatch => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION,
    async payload() {
      const resp = await uploadRecallingSentenceDistribution(
        'recallingSentence',
        formData
      )
      return resp.data
    }
  })

  return response
    .then(() => {
      Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Recalling Sentence uploaded Correspondence History file
 */

export const setRecallSentenceCorrespondenceHistory = data => dispatch => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY,
    async payload() {
      const resp = await uploadCorrespondenceHistory('recallingSentence', formData)
      return resp.data
    }
  })

  return response
    .then(async () => {
      dispatch(getRecallingSentences())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}

/**
 * Update Participant Recalling Sentence uploaded Response file
 */
export const setRecallSentenceResponse = data => dispatch => {
  const formData = new FormData()
  formData.append('file', data)
  const response = dispatch({
    type: RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_RESPONSE,
    async payload() {
      const resp = await uploadConsent('recallingSentence', formData)
      return resp.data
    }
  })

  return response
    .then(async () => {
      dispatch(getRecallingSentences())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}


/**
 * Update Recalling Sentence Contact Status i.e: EmailSent, SMSSent, callMade, endDate
 */
export const updateRecallingSentenceContactStatus = data => dispatch => {
  const response = dispatch({
    type: RECALLINGSENTENCE.UPDATE_RECALLING_SENTENCE_CONTACT_STATUS,
    async payload() {
      const resp = await editSurveyStatus(data.id, {
        emailDate: data.emailDate !== null ? moment(data.emailDate).format() : null,
        smsDate: data.smsDate !==null ? moment(data.smsDate).format() : null,
        callDate: data.callDate !==null ? moment(data.callDate).format(): null,
        invitationDate: data.invitationDate !==null ? moment(data.invitationDate).format(): null,
        endDate: data.endDate !== null ? moment(data.endDate).format() : null
      })
      return resp.data
    }
  })

  return response
    .then(async () => {
      dispatch(getRecallingSentences())
      message.success(
        get(
          response,
          'message',
          RECALLING_SENT_MSGS.EDIT_RECALLING_SENTENCE_CONTACT_STATUS_SUCCESS_MSG
        )
      )
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}


/**
 * Delete Recalling Sentence Tasks i.e:  ResponseId, CompletionDate,
 */
export const deleteRecallingSentenceTask = id => dispatch => {
  const response = dispatch({
    type: RECALLINGSENTENCE.DELETE_RECALLING_SENTENCE_TASK,
    async payload() {
      const resp = await deleteSurveyStatus(id, 'recallingSentence')
      return resp.data
    }
  })

  return response
    .then(async () => {
      dispatch(getRecallingSentences())
      return Promise.resolve(true)
    })
    .catch(() => Promise.reject(false))
}
