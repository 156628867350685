import produce from 'immer'
import { PARENT, PARTICIPANT, DASHBOARD, RECALLINGSENTENCE, PARENT_MP1 } from '../actionTypes'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  stats: null,
  loading: {
    fetchAll: false,
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${DASHBOARD.SET_STATS}_${PROMISE_STATUS.FULFILLED}`: {
      state.stats = action.payload
      break
    }
    case `${DASHBOARD.SET_STATS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${DASHBOARD.SET_STATS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
