import React, { useState, useEffect } from 'react'
import { Button, Divider, Table, Descriptions } from 'antd'
import Modal from '@components/modals/index'
import CurrentContactTable from '@components/table'
import ContactsForm from '@components/forms/contacts'
import ContactDetailForm from '@components/forms/contactDetails'
import useModal from '@src/hooks/useModal'
import styled from 'styled-components'
import ContactEditForm from '@components/forms/contactDetails/editContactForm'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 50px;
  }
`

export default function OtherContactsContainer(props) {
  const {
    otherContacts,
    setContact,
    setContactDetail,
    setEditOtherContact,
    participantToDetails,
    updateContactAccess
  } = props

  const { visible, handleVisibility } = useModal()
  const [contactSaved, setContactSaved] = useState(false)
  const [contactDetailSaved, setContactDetailSaved] = useState(false)
  const [editContactSaved, setEditContactSaved] = useState(false)
  const [contactClick, setContactClick] = useState(null)
  const [contactRowClick, setContactRowClick] = useState({})

  const handleToggleAccess = record => {
    const updateData = {
      ...record,
      participantId: participantToDetails.id
    }
    updateContactAccess(updateData)
      .then(() => setContactSaved(true))
      .catch(() => setContactSaved(false))
  }

  const columns = [
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
    { title: 'Relationship', dataIndex: 'relationship', key: 'relationship' },
    {
      title: 'Make Primary',
      width: 150,
      render: record => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/*<Switch*/}
          {/*size={'small'}*/}
          {/*checked={form.enabled}*/}
          {/*onClick={()=>handleToggleAccess(record,form)}*/}
          {/*/>*/}
          {/*<Divider type="vertical" />*/}
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleToggleAccess(record)}
            >
              Yes
            </Button>
          </ButtonContainer>
        </div>
      )
    }
  ]

  const handleModalVisible = e => {
    e.currentTarget.id === 'NewContact'
      ? setContactClick('new-contact')
      : e.currentTarget.id === 'EditContactDetail'
      ? setContactClick('edit-contact-detail')
      : setContactClick('edit-contact')
    handleVisibility(true)
  }
  const handleCreate = async form => {
    const setType = form.isPrimaryContact ? 'primary' : 'other'
    const formData = {
      ...form,
      type: setType,
      participantId: participantToDetails.id
    }
    setContact(formData)
      .then(() => setContactSaved(true))
      .catch(() => setContactSaved(false))
    handleVisibility(false)
  }

  const handleCreateDetail = async form => {
    setContactDetail(form)
      .then(() => setContactDetailSaved(true))
      .catch(() => setContactDetailSaved(false))
    handleVisibility(false)
  }

  const handleEditContact = async form => {
    setEditOtherContact(form)
      .then(() => setEditContactSaved(true))
      .catch(() => setEditContactSaved(false))
    handleVisibility(false)
  }
  const expandedRow = row => {
    // useEffect(() => { setContactRowClick(row) }, [])
    setContactRowClick(row)

    const columns = [
      { title: 'Address', dataIndex: 'address', key: 'address' },
      { title: 'State', dataIndex: 'state', key: 'state' },
      { title: 'Suburb', dataIndex: 'suburb', key: 'suburb' },
      { title: 'Post Code', dataIndex: 'postalCode', key: 'postalCode' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Mobile #', dataIndex: 'mobileNo', key: 'mobileNo' },
      { title: 'Phone #', dataIndex: 'phoneNo', key: 'phoneNo' },
      { title: 'Work Phone #', dataIndex: 'workPhoneNo', key: 'workPhoneNo' }
    ]

    for (let i = 0; i < otherContacts.length; ++i) {
      if (row.id === otherContacts[i].id) {
        /// For previous contact table
        const inTable = otherContacts[i].previousContactDetails

        /// For current contact
        const otherContactsCurrentDetail = otherContacts[i]?.currentContactDetails
        return (
          <>
            <Divider orientation="left">Contact Details:</Divider>
            <Button
              id="EditContactDetail"
              onClick={handleModalVisible}
              type="primary"
              style={{ marginBottom: 16, background: '#008385' }}
            >
              Edit Contact Details
            </Button>
            <span style={{ padding: '10px' }}></span>
            <Button
              id="EditContact"
              onClick={handleModalVisible}
              type="primary"
              style={{ marginBottom: 16, background: '#008385' }}
            >
              Edit Contact
            </Button>
            <Descriptions>
              <Descriptions.Item label="State">
                {otherContactsCurrentDetail?.state}
              </Descriptions.Item>
              <Descriptions.Item label="Suburb">
                {otherContactsCurrentDetail?.suburb}
              </Descriptions.Item>
              <Descriptions.Item label="Post Code">
                {otherContactsCurrentDetail?.postalCode}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {otherContactsCurrentDetail?.address}
              </Descriptions.Item>
              <Descriptions.Item label="Work Phone#">
                {otherContactsCurrentDetail?.workPhoneNo}
              </Descriptions.Item>
              <Descriptions.Item label="Phone#">
                {otherContactsCurrentDetail?.phoneNo}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile #">
                {otherContactsCurrentDetail?.mobileNo}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {otherContactsCurrentDetail?.email}
              </Descriptions.Item>
            </Descriptions>
            <Table
              columns={columns}
              dataSource={inTable}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
              rowKey="id"
            />
          </>
        )
      }
    }
  }
  return (
    <div>
      <Button
        id="NewContact"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Add
      </Button>
      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={1600}
        title={
          contactClick === 'new-contact'
            ? 'Add A New Contact'
            : contactClick === 'edit-contact-detail'
            ? 'Edit A Contact Detail'
            : 'Edit A Contact'
        }
        destroyOnClose
      >
        {contactClick === 'new-contact' ? (
          <ContactsForm data={otherContacts} onContactSubmit={handleCreate} />
        ) : contactClick === 'edit-contact-detail' ? (
          <ContactDetailForm
            data={contactRowClick}
            onContactDetailSubmit={handleCreateDetail}
          />
        ) : (
          <ContactEditForm
            data={contactRowClick}
            onContactEditSubmit={handleEditContact}
          />
        )}
      </Modal>

      <CurrentContactTable
        columns={columns}
        data={otherContacts}
        expandedRowRender={expandedRow}
        rowKey="id"
        size="small"
      />
    </div>
  )
}
