import React, { useEffect } from 'react'
import {
  getParticipant,
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  updateParticipantContactStatus,
  deleteParticipantConsentSurvey,
} from '@actions/participantActions'

import {
  updateParentContactStatus,
  deleteParentConsentSurvey,
} from '@actions/parentActions'
import {
  getMPOneParent,
  setParentMPOneConsent,
  setParentMPOneCorrespondenceHistory,
  updateParentMP1ContactStatus,
  deleteParentMP1ConsentSurvey,
  getParentMPOneWorkSheetDownload,
} from '@actions/parentMPOneActions'

import {
  getMPTwoParent,
  setParentMPTwoConsent,
  setParentMPTwoCorrespondenceHistory,
  deleteParentMP2ConsentSurvey,
  updateParentMP2ContactStatus,
  getParentMPTwoWorkSheetDownload,
} from '@actions/parentMPTwoActions'

import {
  updateRecallingSentenceContactStatus,
  deleteRecallingSentenceTask,
} from '@actions/recallingSentenceActions'

import {
  updateParticipantMP1ContactStatus,
  deleteParticipantMP1ConsentSurvey,
} from '@actions/participantMPOneActions'

import {
  updateParticipantMP2ContactStatus,
  deleteParticipantMP2ConsentSurvey,
} from '@actions/participantMPTwoActions'

import ParentMPTwoList from '@containers/parentMPTwo/parentMPTwoList'
import connect from 'react-redux/es/connect/connect'
import ParticipantDetails from '@containers/participants/details'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'
import { Spin } from 'antd'

function ParticipantModal(props) {
  const {
    //States
    loading,
    user,
    participantToDetails,
    //Dispatch
    setComment,
    updateComment,
    deleteComment,
    setAction,
    updateActionComplete,
    updateAction,
    setContact,
    setContactDetail,
    setPrimaryContact,
    setPrimaryContactDetail,
    setEditPrimaryContact,
    setEditOtherContact,
    setParticipantContactDetail,
    updateContactAccess,
    setSchoolDetail,
    updateParticipantDetail,
    getMPOneParent,
    getMPTwoParent,
    setParentMPOneConsent,
    setParentMPOneCorrespondenceHistory,
    updateParticipantContactStatus,
    updateParentContactStatus,
    updateRecallingSentenceContactStatus,
    getParentMPOneWorkSheetDownload,
    updateParentMP1ContactStatus,
    updateParentMP2ContactStatus,
    updateParticipantMP1ContactStatus,
    updateParticipantMP2ContactStatus,
    deleteParticipantConsentSurvey,
    deleteParentConsentSurvey,
    deleteRecallingSentenceTask,
    deleteParentMP1ConsentSurvey,
    deleteParentMP2ConsentSurvey,
    deleteParticipantMP1ConsentSurvey,
    deleteParticipantMP2ConsentSurvey,
    parentComponent,
    visible,
    handleVisibility,
  } = props

  return (
    <div>
      <ModalComponent
        width={1200}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          participantToDetails
            ? 'Participant Details : ' +
              participantToDetails.participantId +
              ' - ' +
              participantToDetails.firstName +
              ' ' +
              participantToDetails.lastName
            : ''
        }
      >
        <ParticipantDetails
          participantToDetails={participantToDetails}
          user={user}
          setComment={setComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          setAction={setAction}
          updateAction={updateAction}
          updateActionComplete={updateActionComplete}
          setContact={setContact}
          setContactDetail={setContactDetail}
          setPrimaryContact={setPrimaryContact}
          setPrimaryContactDetail={setPrimaryContactDetail}
          setEditPrimaryContact={setEditPrimaryContact}
          setEditOtherContact={setEditOtherContact}
          setParticipantContactDetail={setParticipantContactDetail}
          updateContactAccess={updateContactAccess}
          setSchoolDetail={setSchoolDetail}
          updateParticipantDetail={updateParticipantDetail}
          updateParticipantContactStatus={updateParticipantContactStatus}
          updateParentContactStatus={updateParentContactStatus}
          updateRecallingSentenceContactStatus={updateRecallingSentenceContactStatus}
          updateParentMP1ContactStatus={updateParentMP1ContactStatus}
          updateParentMP2ContactStatus={updateParentMP2ContactStatus}
          updateParticipantMP1ContactStatus={updateParticipantMP1ContactStatus}
          updateParticipantMP2ContactStatus={updateParticipantMP2ContactStatus}
          deleteParticipantConsentSurvey={deleteParticipantConsentSurvey}
          deleteParentConsentSurvey={deleteParentConsentSurvey}
          deleteRecallingSentenceTask={deleteRecallingSentenceTask}
          deleteParentMP1ConsentSurvey={deleteParentMP1ConsentSurvey}
          deleteParentMP2ConsentSurvey={deleteParentMP2ConsentSurvey}
          deleteParticipantMP1ConsentSurvey={deleteParticipantMP1ConsentSurvey}
          deleteParticipantMP2ConsentSurvey={deleteParticipantMP2ConsentSurvey}
          // parentComponent="parent-mp2-consents"
          parentComponent={parentComponent}
        />
      </ModalComponent>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  participantToDetails: combineReducer.participantToDetails,
  loading: combineReducer.loading,
  user: user.user,
})

const mapDispatchToProps = {
  getParticipant: (id) => getParticipant(id),
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  getMPOneParent,
  getMPTwoParent,
  setParentMPOneConsent,
  setParentMPOneCorrespondenceHistory,
  updateParticipantContactStatus,
  updateParentContactStatus,
  updateRecallingSentenceContactStatus,
  getParentMPOneWorkSheetDownload,
  updateParentMP1ContactStatus,
  updateParentMP2ContactStatus,
  updateParticipantMP1ContactStatus,
  updateParticipantMP2ContactStatus,
  deleteParticipantConsentSurvey,
  deleteParentConsentSurvey,
  deleteRecallingSentenceTask,
  deleteParentMP1ConsentSurvey,
  deleteParentMP2ConsentSurvey,
  deleteParticipantMP1ConsentSurvey,
  deleteParticipantMP2ConsentSurvey,
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantModal)
