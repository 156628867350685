import produce from 'immer'
import {
  PARENT,
  PARTICIPANT,
  RECALLINGSENTENCE,
  PARENT_MP1,
  PARTICIPANT_MP1,
  PARENT_MP2,
  PARTICIPANT_MP2,
} from '../actionTypes'
import { PROMISE_STATUS } from '@constants/strings'

const initialState = {
  participants: null,
  participantToDetails: null,
  participantConsents: null,
  parentConsents: null,
  recallingSentences: null,
  parentMPOne: null,
  participantMPOne: null,
  parentMPTwo: null,
  participantMPTwo: null,
  // stats: null,
  loading: {
    fetchAll: false,
    fetchOne: false,
    downloadWorksheet: false,
    uploadingSheet: false,
    uploadingDistParent: false,
    uploadingDistParticipant: false,
    uploadingDistRecallSentence: false,
    uploadingDistParentMPOne: false,
    uploadingDistParticipantMPOne: false,
    uploadingDistParentMPTwo: false,
    uploadingDistParticipantMPTwo: false,
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case `${PARTICIPANT.SET_PARTICIPANTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participants = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participants.unshift(payload)
      state.participantToDetails = payload
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantConsents = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANTS_TO_DETAILS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantToDetails = action.payload
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANTS_TO_DETAILS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANTS_TO_DETAILS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_COMMENT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.comments.splice(0, 0, payload)
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_COMMENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_COMMENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.participantToDetails.comments.findIndex(
        (item) => item.id === payload.id
      )
      state.participantToDetails.comments[index] = payload
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.DELETE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.participantToDetails.comments.findIndex(
        (item) => item.id === payload.commentId
      )
      state.participantToDetails.comments.splice(index, 1)
      break
    }
    case `${PARTICIPANT.DELETE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.DELETE_PARTICIPANT_COMMENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_ACTION}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.actions.splice(0, 0, payload)
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_ACTION}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_ACTION}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.UPDATE_NO_OF_ACTIONS_DUE}`: {
      const index = state.participants.findIndex(
        (item) => item.id === state.participantToDetails.id
      )
      state.participants[index].noOfActionsDue =
        state.participantToDetails.noOfActionsDue
      state.participants[index].latestDueDate =
        state.participantToDetails.latestDueDate
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.participantToDetails.actions.findIndex(
        (item) => item.id === payload.id
      )
      state.participantToDetails.actions[index] = payload
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.participantToDetails.actions.findIndex(
        (item) => item.id === payload.id
      )
      state.participantToDetails.actions[index] = payload
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.otherContacts.push(payload)
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload

      const index = state.participantToDetails.otherContacts.findIndex(
        (item) => item.id === payload.contactId
      )
      state.participantToDetails.otherContacts[index].previousContactDetails.push(
        state.participantToDetails.otherContacts[index].currentContactDetails
      )
      state.participantToDetails.otherContacts[index].currentContactDetails =
        payload.data
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }

    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.otherContacts.push(
        state.participantToDetails.primaryContact
      )
      state.participantToDetails.primaryContact = payload

      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }

    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      if (state.participantToDetails.primaryContact.currentContactDetails != null) {
        // state.participantToDetails.primaryContact.previousContactDetails.push(
        //   state.participantToDetails.primaryContact.currentContactDetails
        // )
        state.participantToDetails.primaryContact.previousContactDetails.splice(
          0,
          0,
          state.participantToDetails.primaryContact.currentContactDetails
        )
      }
      state.participantToDetails.primaryContact.currentContactDetails = payload
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_PRIMARY_CONTACT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.primaryContact = payload
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_PRIMARY_CONTACT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_PRIMARY_CONTACT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }

    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_OTHER_CONTACT}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload

      const index = state.participantToDetails.otherContacts.findIndex(
        (item) => item.id === payload.id
      )
      state.participantToDetails.otherContacts[index] = payload
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_OTHER_CONTACT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_EDIT_OTHER_CONTACT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_CONTACT_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      if (state.participantToDetails.currentContactDetails != null) {
        // state.participantToDetails.previousContactDetails.push(
        //   state.participantToDetails.currentContactDetails
        // )
        state.participantToDetails.previousContactDetails.splice(
          0,
          0,
          state.participantToDetails.currentContactDetails
        )
      }
      state.participantToDetails.currentContactDetails = payload

      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_CONTACT_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_CONTACT_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_PRIMARY}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      const index = state.participantToDetails.otherContacts.findIndex(
        (item) => item.id === payload.id
      )
      state.participantToDetails.otherContacts[index] =
        state.participantToDetails.primaryContact
      state.participantToDetails.primaryContact = payload
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_PRIMARY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_PRIMARY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_SCHOOL_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      if (state.participantToDetails.currentSchoolDetails != null) {
        // state.participantToDetails.previousSchoolDetails.push(
        //   state.participantToDetails.currentSchoolDetails
        // )
        state.participantToDetails.previousSchoolDetails.splice(
          0,
          0,
          state.participantToDetails.currentSchoolDetails
        )
      }
      state.participantToDetails.currentSchoolDetails = payload
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_SCHOOL_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_SCHOOL_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_DETAIL}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails = payload
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_DETAIL}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchOne = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_DETAIL}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchOne = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParticipant = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParticipant = true
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParticipant = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParent = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParent = true
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParent = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      // Need to change
      state.participantToDetails.participantConsentDetails.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.participantConsentDetails.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.participantConsentDetails.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.participantConsentDetails.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.participantConsentDetails.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT.UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }

    case `${PARTICIPANT.DELETE_PARTICIPANT_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.participantConsentDetails.assistConsent = null
      state.participantToDetails.participantConsentDetails.projectConsent = null
      state.participantToDetails.participantConsentDetails.vtacConsent = null
      state.participantToDetails.participantConsentDetails.surveyBase.responseId = null
      state.participantToDetails.participantConsentDetails.surveyBase.endDate = null
      break
    }
    case `${PARTICIPANT.DELETE_PARTICIPANT_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT.DELETE_PARTICIPANT_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    // PARENT's ACTIONS
    case `${PARENT.SET_PARENT_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.parentConsents = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARENT.SET_PARENT_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT.SET_PARENT_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT.UPDATE_PARENT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.parentConsentDetails.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.parentConsentDetails.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.parentConsentDetails.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.parentConsentDetails.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.parentConsentDetails.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARENT.UPDATE_PARENT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT.UPDATE_PARENT_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT.DELETE_PARENT_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.parentConsentDetails.participantEmail = null
      state.participantToDetails.parentConsentDetails.participantName = null
      state.participantToDetails.parentConsentDetails.participantPhoneNo = null
      state.participantToDetails.parentConsentDetails.projectConsent = null
      state.participantToDetails.parentConsentDetails.schoolName = null
      state.participantToDetails.parentConsentDetails.schoolStatus = null
      state.participantToDetails.parentConsentDetails.schoolState = null
      state.participantToDetails.parentConsentDetails.schoolSuburb = null
      state.participantToDetails.parentConsentDetails.schoolYearLevel = null
      state.participantToDetails.parentConsentDetails.teenContactConsent = null
      state.participantToDetails.parentConsentDetails.surveyBase.responseId = null
      state.participantToDetails.parentConsentDetails.surveyBase.endDate = null
      break
    }
    case `${PARENT.DELETE_PARENT_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT.DELETE_PARENT_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARTICIPANT.SET_PARTICIPANT_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT.SET_PARENT_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT.SET_PARENT_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARENT.SET_PARENT_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALL_SENTENCE_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALL_SENTENCE_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALL_SENTENCE_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT.SET_UPLOAD_PARENT_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT.SET_UPLOAD_PARTICIPANT_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistRecallSentence = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistRecallSentence = true
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistRecallSentence = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_RESPONSE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_RESPONSE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_RESPONSE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALLING_SENTENCES}_${PROMISE_STATUS.FULFILLED}`: {
      state.recallingSentences = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALLING_SENTENCES}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${RECALLINGSENTENCE.SET_RECALLING_SENTENCES}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${RECALLINGSENTENCE.UPDATE_RECALLING_SENTENCE_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.recallingSentenceDetails.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.recallingSentenceDetails.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.recallingSentenceDetails.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.recallingSentenceDetails.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.recallingSentenceDetails.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${RECALLINGSENTENCE.UPDATE_RECALLING_SENTENCE_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${RECALLINGSENTENCE.UPDATE_RECALLING_SENTENCE_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${RECALLINGSENTENCE.DELETE_RECALLING_SENTENCE_TASK}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.recallingSentenceDetails.surveyBase.endDate = null
      break
    }
    case `${RECALLINGSENTENCE.DELETE_RECALLING_SENTENCE_TASK}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${RECALLINGSENTENCE.DELETE_RECALLING_SENTENCE_TASK}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    // PARENT MP ONE ACTIONS
    case `${PARENT_MP1.SET_PARENT_MP1_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.parentMPOne = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP1.SET_PARENT_MP1_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP1.SET_PARENT_MP1_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParentMPOne = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParentMPOne = true
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParentMPOne = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT_MP1.SET_UPLOAD_PARENT_MP1_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP1.SET_PARENT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT_MP1.SET_PARENT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARENT_MP1.SET_PARENT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT_MP1.UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.parentMp1Details.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.parentMp1Details.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.parentMp1Details.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.parentMp1Details.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.parentMp1Details.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARENT_MP1.UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP1.UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP1.DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.parentMp1Details.surveyBase.responseId = null
      state.participantToDetails.parentMp1Details.surveyBase.endDate = null
      break
    }
    case `${PARENT_MP1.DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP1.DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    // PARTICIPANT MP ONE ACTIONS
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantMPOne = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParticipantMPOne = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParticipantMPOne = true
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParticipantMPOne = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARTICIPANT_MP1.SET_PARTICIPANT_MP1_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARTICIPANT_MP1.UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.participantMp1Details.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.participantMp1Details.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.participantMp1Details.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.participantMp1Details.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.participantMp1Details.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARTICIPANT_MP1.UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP1.UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP1.DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.participantMp1Details.surveyBase.responseId = null
      state.participantToDetails.participantMp1Details.surveyBase.endDate = null
      break
    }
    case `${PARTICIPANT_MP1.DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP1.DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    // PARENT MP TWO ACTIONS
    case `${PARENT_MP2.SET_PARENT_MP2_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.parentMPTwo = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP2.SET_PARENT_MP2_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP2.SET_PARENT_MP2_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParentMPTwo = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParentMPTwo = true
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParentMPTwo = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARENT_MP2.SET_UPLOAD_PARENT_MP2_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARENT_MP2.SET_PARENT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT_MP2.SET_PARENT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARENT_MP2.SET_PARENT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARENT_MP2.UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.parentMp2Details.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.parentMp2Details.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.parentMp2Details.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.parentMp2Details.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.parentMp2Details.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARENT_MP2.UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP2.UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARENT_MP2.DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantToDetails.parentMp2Details.surveyBase.responseId = null
      state.participantToDetails.parentMp2Details.surveyBase.endDate = null
      break
    }
    case `${PARENT_MP2.DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARENT_MP2.DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    // PARTICIPANT MP TWO ACTIONS
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_CONSENTS}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantMPTwo = action.payload
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_CONSENTS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_CONSENTS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingDistParticipantMPTwo = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingDistParticipantMPTwo = true
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingDistParticipantMPTwo = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CONSENT}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CONSENT}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CONSENT}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.uploadingSheet = true
      break
    }
    case `${PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.uploadingSheet = false
      break
    }
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.FULFILLED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.PENDING}`: {
      state.loading.downloadWorksheet = true
      break
    }
    case `${PARTICIPANT_MP2.SET_PARTICIPANT_MP2_DOWNLOAD_FILE}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.downloadWorksheet = false
      break
    }
    case `${PARTICIPANT_MP2.UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.FULFILLED}`: {
      const payload = action.payload
      state.participantToDetails.participantMp2Details.surveyBase.emailDate =
        payload.emailDate
      state.participantToDetails.participantMp2Details.surveyBase.smsDate =
        payload.smsDate
      state.participantToDetails.participantMp2Details.surveyBase.callDate =
        payload.callDate
      state.participantToDetails.participantMp2Details.surveyBase.invitationDate =
        payload.invitationDate
      state.participantToDetails.participantMp2Details.surveyBase.endDate =
        payload.endDate
      break
    }
    case `${PARTICIPANT_MP2.UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP2.UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    case `${PARTICIPANT_MP2.DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.FULFILLED}`: {
      state.participantToDetails.participantMp2Details.surveyBase.responseId = null
      state.participantToDetails.participantMp2Details.surveyBase.endDate = null
      break
    }
    case `${PARTICIPANT_MP2.DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.PENDING}`: {
      state.loading.fetchAll = true
      break
    }
    case `${PARTICIPANT_MP2.DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY}_${PROMISE_STATUS.REJECTED}`: {
      state.loading.fetchAll = false
      break
    }
    default:
      return state
  }
}

export default produce(reducer, initialState)
