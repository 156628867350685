import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Divider, Input } from 'antd'
import connect from 'react-redux/es/connect/connect'
import {
  setNewUser,
  updateUserDetail,
  getUsers,
  updateResetPassword,
  updateChangePassword
} from '@actions/userActions'
import styled from 'styled-components'
import Modal from '@components/modals'
import UserForm from '@components/forms/userManagement'
import UserEditForm from '@components/forms/userManagement/editUser'
import UserResetForm from '@components/forms/userManagement/resetUserPassword'
import UserChangePasswordForm from '@components/forms/userManagement/changePassword'
import UserTable from '@components/table'
import useModal from '@src/hooks/useModal'
import { EditOutlined, KeyOutlined, LockOutlined } from '@ant-design/icons'

const MainDiv = styled.div`
  font-weight: 700;
  font-size: 22px;
  background-color: #f4f4f4;
  color: #333333;
`
function UserManagementContainer(props) {
  const {
    //State
    users,
    user,
    newPassword,
    //Dispatch
    getUsers,
    setNewUser,
    updateUserDetail,
    updateResetPassword,
    updateChangePassword
  } = props

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const { visible, handleVisibility } = useModal()
  const [newUserSaved, setNewUserSaved] = useState(false)
  const [editUserSaved, setEditUserSaved] = useState(false)
  const [editAnotherUserPasswordSaved, setEditAnotherUserPasswordSaved] = useState(
    false
  )
  const [editChangePasswordSaved, setChangePasswordSaved] = useState(false)
  const [buttonClick, setButtonClick] = useState('')
  const [editedData, setEditedData] = useState({})
  const [filterTable, setFilterTable] = useState(null)

  const data = users && users
  const userMatch = users?.filter(item => item.id === user.id)

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 300,
      render: (_, record) => (
        <span>
          <Tooltip title="Edit User Info">
            <a
              id="UserEdit"
              style={{ marginRight: 16 }}
              onClick={handleModalVisible}
            >
              <EditOutlined />
            </a>
          </Tooltip>
          <Divider type="vertical" />
          {userMatch[0] && record.id !== userMatch[0]?.id && (
            <Tooltip title="Reset Other User Password">
              <a
                id="UserReset"
                style={{ marginRight: 16 }}
                onClick={handleModalVisible}
              >
                <KeyOutlined />
              </a>
            </Tooltip>
          )}
          <Divider type="vertical" />
          {userMatch[0] && record.id === userMatch[0]?.id && (
            <Tooltip title="Change Your Own Password">
              <a
                id="ChangePassword"
                style={{ marginRight: 16 }}
                onClick={handleModalVisible}
              >
                <LockOutlined />
              </a>
            </Tooltip>
          )}
        </span>
      )
    }
  ]

  const handleModalVisible = e => {
    e.currentTarget.id === 'UserAdd'
      ? setButtonClick('add')
      : e.currentTarget.id === 'UserEdit'
      ? setButtonClick('edit')
      : e.currentTarget.id === 'UserReset'
      ? setButtonClick('reset')
      : setButtonClick('change')
    handleVisibility(true)
  }

  const handleCreateUser = async form => {
    setNewUser(form)
      .then(() => setNewUserSaved(true))
      .catch(() => setNewUserSaved(false))
    handleVisibility(false)
  }

  const handleEditUser = async form => {
    updateUserDetail(form)
      .then(() => setEditUserSaved(true))
      .catch(() => setEditUserSaved(false))
    handleVisibility(false)
  }

  const handleResetAnotherUserPassword = async form => {
    updateResetPassword(form)
      .then(() => setEditAnotherUserPasswordSaved(true))
      .catch(() => setEditAnotherUserPasswordSaved(false))
    // handleVisibility(false)
  }

  const handleResetPasswordCancel = async e => {
    handleVisibility(false)
  }

  const handleChangeUserPassword = async form => {
    updateChangePassword(form)
      .then(() => setChangePasswordSaved(true))
      .catch(() => setChangePasswordSaved(false))
    handleVisibility(false)
  }

  const search = value => {
    const filterTable = data.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    )
    setFilterTable(filterTable)
  }

  return (
    <div>
      <MainDiv> User Management </MainDiv>
      <div className="flex-container">
        <div style={{ width: '1800px' }}>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse' }}>

            <Button
              id="UserAdd"
              onClick={handleModalVisible}
              type="primary"
              style={{ marginBottom: 16, background: '#008385' }}
            >
              Create User
            </Button>
            <Input.Search
              style={{ paddingRight: '10px', float: 'left' }}
              placeholder="Search by keyword ..."
              enterButton
              // onSearch={search}
              onChange={e => search(e.target.value)}
            />
          </div>

          <Modal
            visible={visible}
            handleVisibility={handleVisibility}
            width={750}
            minHeight={200}
            title={
              buttonClick === 'add'
                ? 'Add A New User'
                : buttonClick === 'edit'
                ? 'Edit A User Details'
                : buttonClick === 'reset'
                ? 'Reset Other User Password'
                : 'Change Your Own Password'
            }
            destroyOnClose
          >
            {buttonClick === 'add' ? (
              <UserForm onUserSubmit={handleCreateUser} />
            ) : buttonClick === 'edit' ? (
              <UserEditForm data={editedData} onUpdateUserSubmit={handleEditUser} />
            ) : buttonClick === 'reset' ? (
              <UserResetForm
                data={editedData}
                newPassword={newPassword}
                onUpdateResetPasswordSubmit={handleResetAnotherUserPassword}
                onResetPasswordSubmitCancel={handleResetPasswordCancel}
              />
            ) : (
              <UserChangePasswordForm
                data={editedData}
                onUpdatePasswordSubmit={handleChangeUserPassword}
              />
            )}
          </Modal>
          <UserTable
            columns={columns}
            data={filterTable == null ? data : filterTable}
            rowKey="id"
            size="small"
            tableLayout="fixed"
            onRowClick={row => ({
              onClick: () => setEditedData(row)
            })}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  users: user.users,
  newPassword: user.new_password,
  loading: user.loading
})

const mapDispatchToProps = {
  getUsers,
  setNewUser,
  updateUserDetail,
  updateResetPassword,
  updateChangePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementContainer)
