import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ROUTES from '@constants/routes'
import Login from '@containers/login'
import ParentConsent from '@containers/parents'
import ParticipantList from '@containers/participants'
import ParticipantConsent from '@containers/participantConsent'
import DistributionHistory from '@containers/distributionHistory'
import RecallSentence from '@containers/recallSentence'
import ParentMPOne from '@containers/parentMPOne'
import ParticipantMPOne from '@containers/participantMPOne'
import ParticipantMPTwo from '@containers/participantMPTwo'
import Dashboard from '@containers/dashboard'
import UserManagement from '@containers/userManagement'
export function LoginRoutes() {
  return (
    <Switch>
      <Route exact path={ROUTES.login} component={Login} />
      <Redirect exact to={ROUTES.login} />
    </Switch>
  )
}

export function AppRoutes(props) {
  return (
    <Switch>
      <Route exact path={ROUTES.dashboard} component={Dashboard} />
      <Route exact path={ROUTES.participantList} component={ParticipantList} />
      <Route exact path={ROUTES.parentConsent} component={ParentConsent} />
      <Route exact path={ROUTES.participantConsent} component={ParticipantConsent} />
      <Route exact path={ROUTES.recallingSentence} component={RecallSentence} />
      <Route exact path={ROUTES.parentMPOne} component={ParentMPOne} />
      <Route exact path={ROUTES.participantMPOne} component={ParticipantMPOne} />
      <Route exact path={ROUTES.participantMPTwo} component={ParticipantMPTwo} />
      <Route
        exact
        path={ROUTES.distributionHistory}
        component={DistributionHistory}
      />
      <Route exact path={ROUTES.userManagement} component={UserManagement} />
      <Redirect exact to={ROUTES.dashboard} />
    </Switch>
  )
}
