import { DASHBOARD } from '@store/actionTypes'
import { fetchAllStats } from '@api/dashboard'

/**
 * Get ELVS Stats for Dashboard
 */
export const getStats = () => {
  return {
    type: DASHBOARD.SET_STATS,
    async payload() {
      const resp = await fetchAllStats()
      return resp.data
    }
  }
}
