import React, { useEffect, useState } from 'react'
import { Button, Alert, Spin } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'

export default function DownloadComponent(props) {
  const {
    rawData,
    buttonLabel,
    getDataDownload,
    componentId,
    loading,
    filters,
  } = props
  const { visible, handleVisibility } = useModal()


  const [selectedDataForDownload, setSelectedDataForDownload] = useState(null)

  useEffect(() => {
    filters === null ? setSelectedDataForDownload(null) : getFilterData()
  }, [filters])

  const onDownloadClick = async (e) => {
    componentId === 'parent-consent' || componentId === 'recalling-sentence'
      ? getDataDownload(
          flatOut(
            selectedDataForDownload !== null ? selectedDataForDownload : rawData,
            'participantUuid'
          )
        )
      : componentId === 'participant-consent' ||
        componentId === 'parent-mpone-consent' ||
        componentId === 'parent-mptwo-consent' ||
        componentId === 'participant-mpone-consent' ||
        componentId === 'participant-mptwo-consent'
      ? getDataDownload(
          flatOut(
            selectedDataForDownload !== null ? selectedDataForDownload : rawData,
            'id'
          )
        )
      : getDataDownload([])
  }

  function flatOut(array, key) {
    return array.map(function (item) {
      return item[key]
    })
  }

  const showModal = (e) => {
    handleVisibility(true)
  }

  const getFilterData = () => {
    const filterKeys = Object.keys(filters)
    var filteredRecords = rawData.filter((item) => {
      // validates all filter criteria
      return filterKeys.every((key) => {
        // ignores an empty filter
        if (filters[key].value === 'Provided') {
          if (item[key] == null) {
            return false
          } else {
            return true
          }
        } else if (filters[key].value === 'Empty') {
          if (item[key] != null) {
            return false
          } else {
            return true
          }
        } else if (
          key === 'daysPostInvitation' ||
          key === 'daysPostSms' ||
          key === 'daysPostEmail' ||
          key === 'daysPostCall' ||
          key === 'daysPostMp1Remainder'
        ) {
          if (item[key] >= filters[key].value) {
            return true
          } else {
            return false
          }
        } else if (key === 'schoolStatus') {
          if (item[key] === null && filters[key].value.includes('Empty')) {
            return true
          } else if (
            item[key] === 'Still going to school' &&
            filters[key].value.includes('Still going to school')
          ) {
            return true
          } else if (
            item[key] === 'Graduated from Year 12' &&
            filters[key].value.includes('Graduated from Year 12')
          ) {
            return true
          } else if (
            item[key] === "Left school but didn't graduate" &&
            filters[key].value.includes("Left school but didn't graduate")
          ) {
            return true
          }
        } else if (key === 'schoolYearLevel') {
          if (item[key] === null && filters[key].value.includes('Empty')) {
            return true
          } else if (
            item[key] === 'Year 10' &&
            filters[key].value.includes('Year 10')
          ) {
            return true
          } else if (
            item[key] === 'Year 9 or below' &&
            filters[key].value.includes('Year 9 or below')
          ) {
            return true
          } else if (
            item[key] === 'Year 11' &&
            filters[key].value.includes('Year 11')
          ) {
            return true
          } else if (
            item[key] === 'Year 12' &&
            filters[key].value.includes('Year 12')
          ) {
            return true
          } else if (item[key] === 'IB' && filters[key].value.includes('IB')) {
            return true
          }
        } else {
          if (item[key] == filters[key].value) {
            return true
          } else {
            return false
          }
        }
        // return filters[key].find(filter => filter === item[key])
      })
    })
    setSelectedDataForDownload(filteredRecords)
  }
  return (
    <div>
      <Button
        id="DownloadMailMerge"
        onClick={showModal}
        style={{
          backgroundColor: '#008385',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {buttonLabel}
      </Button>
      <ModalComponent
        width={1000}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          componentId == 'participant-consent'
            ? 'Download Participant Consent Survey Worksheet'
            : componentId == 'parent-consent'
            ? 'Download Parent Consent Survey Worksheet'
            : componentId == 'participant-mpone-consent'
            ? 'Download Participant Measure Package 1 Consent Survey Worksheet'
            : componentId == 'participant-mptwo-consent'
            ? 'Download Participant Measure Package 2 Consent Survey Worksheet'
            : componentId == 'parent-mpone-consent'
            ? 'Download Parent Measure Package 1 Consent Survey Worksheet'
            : componentId == 'parent-mptwo-consent'
            ? 'Download Parent Measure Package 2 Consent Survey Worksheet'
            : 'Download Recalling Sentences Task Worksheet'
        }
      >
        <div>
          <Spin spinning={loading.downloadWorksheet}>
            <Alert
              message="Note for Download"
              description={
                componentId == 'participant-consent' ||
                componentId == 'parent-consent' ||
                componentId == 'participant-mpone-consent' ||
                componentId == 'participant-mptwo-consent' ||
                componentId == 'parent-mptwo-consent' ||
                componentId == 'parent-mpone-consent' ||
                componentId == 'recalling-sentence'
                  ? `${
                      selectedDataForDownload && selectedDataForDownload.length > 0
                        ? selectedDataForDownload && selectedDataForDownload.length
                        : rawData && rawData.length
                    }  records have been selected of Total: ${
                      rawData && rawData.length
                    } records for download!`
                  : ''
              }
              type="info"
              showIcon
            ></Alert>
          </Spin>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <Button
            style={{
              backgroundColor: '#008385',
              color: 'white',
              fontWeight: 'bold',
            }}
            onClick={onDownloadClick}
            disabled={loading.downloadWorksheet}
          >
            <CloudDownloadOutlined /> Download
          </Button>
        </div>
      </ModalComponent>
    </div>
  )
}
