import { Form, Input, Button, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 16
  }
}

function UserEditForm(props) {
  const { onUpdateUserSubmit, data } = props

  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleFinish = values => {
    onUpdateUserSubmit(values)
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  }

  return (
    <Form
      form={form}
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
      initialValues={{
        id: data?.id,
        name: data?.name,
        email: data?.email,
        notes: data?.notes,
        disabled: data?.disabled
      }}
    >
      <Form.Item name="id" label="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name={['name']} label="User Name">
        <Input />
      </Form.Item>
      <Form.Item name={['email']} label="Email">
        <Input />
      </Form.Item>
      <Form.Item name={['notes']} label="Notes">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Disable" name={['disabled']}>
        <Switch defaultChecked={false} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('name') &&
                  !form.isFieldTouched('email') &&
                  !form.isFieldTouched('notes') &&
                !form.isFieldTouched('disabled')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
      </Form.Item>
    </Form>
  )
}
export default UserEditForm
