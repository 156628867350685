import { Form, Input, Button, Select, Row, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 12
  },
  wrapperCol: {
    span: 10
  }
}

function SchoolsForm(props) {
  const { onSchoolDetailSubmit, data } = props
  const [form] = Form.useForm()
  const [, forceUpdate] = useState() // To disable submit button at the beginning

  useEffect(() => {
    forceUpdate({})
  }, [])
  const handleFinish = values => {
    onSchoolDetailSubmit(values)
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  }

  return (
    <Form
      form={form}
      name="nest-messages"
      // className="ant-advanced-search-form"
      validateMessages={validateMessages}
      onFinish={handleFinish}
      initialValues={data}
    >
      <Row gutter={24}>
        <Col span={12} key={1}>
          <Form.Item name={['name']} label="School Name">
            <Input placeholder="Please input School Name" />
          </Form.Item>
        </Col>

        <Col span={12} key={2}>
          <Form.Item name={['suburb']} label="School Suburb">
            <Input placeholder="Please input School Suburb" />
          </Form.Item>
        </Col>
        <Col span={12} key={3}>
          <Form.Item label="State" name={['state']}>
            <Select placeholder="Select State">
              <Select.Option value="ACT">ACT</Select.Option>
              <Select.Option value="QLD">QLD</Select.Option>
              <Select.Option value="NSW">NSW</Select.Option>
              <Select.Option value="NT">NT</Select.Option>
              <Select.Option value="SA">SA</Select.Option>
              <Select.Option value="TAS">TAS</Select.Option>
              <Select.Option value="VIC">VIC</Select.Option>
              <Select.Option value="WA">WA</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} key={4}>
          <Form.Item name={['status']} label="Status">
            <Select placeholder="Select School Status">
              <Select.Option value="Still going to school">Still going to school</Select.Option>
              <Select.Option value="Graduated from Year 12">Graduated from Year 12</Select.Option>
              <Select.Option value="Left school but didn't graduate">Left school but didn't graduate</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} key={5}>
          <Form.Item name={['yearLevel']} label="Year Level">
            <Select placeholder="Select School Year Level" allowClear>
              <Select.Option value="Year 9 or below">Year 9 or below</Select.Option>
              <Select.Option value="Year 10">Year 10</Select.Option>
              <Select.Option value="Year 11">Year 11</Select.Option>
              <Select.Option value="Year 12">Year 12</Select.Option>
              <Select.Option value="IB">IB</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
        {() => (
          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                (!form.isFieldTouched('name') &&
                  !form.isFieldTouched('suburb') &&
                  !form.isFieldTouched('state') &&
                  !form.isFieldTouched('status') &&
                  !form.isFieldTouched('yearLevel')) ||
                form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Submit
            </Button>
          </ButtonContainer>
        )}
        {/*<ButtonContainer>*/}
        {/*<Button type="primary" htmlType="submit"*/}
        {/*disabled={*/}
        {/*!form.isFieldsTouched(true) ||*/}
        {/*form.getFieldsError().filter(({ errors }) => errors.length).length*/}
        {/*}>*/}
        {/*Submit*/}
        {/*</Button>*/}
        {/*</ButtonContainer>*/}
      </Form.Item>
    </Form>
  )
}
export default SchoolsForm
