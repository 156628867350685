import React, { useState } from 'react'
import { Button, Divider, Descriptions, Collapse } from 'antd'
import Modal from '@components/modals/index'
import PrimaryContactsForm from '@components/forms/contacts'
import PrimaryContactDetailForm from '@components/forms/contactDetails'
import PrimaryContactEditForm from '@components/forms/contactDetails/editContactForm'
import useModal from '@src/hooks/useModal'
import PrevPrimaryContactTable from '@components/table'

const { Panel } = Collapse

export default function PrimaryContactContainer(props) {
  const {
    primaryContact,
    setPrimaryContact,
    setPrimaryContactDetail,
    setEditPrimaryContact,
    participantToDetails,
    user
  } = props

  const { visible, handleVisibility } = useModal()
  const [contactSaved, setContactSaved] = useState(false)
  const [contactDetailSaved, setContactDetailSaved] = useState(false)
  const [editContactSaved, setEditContactSaved] = useState(false)
  const [contactClick, setContactClick] = useState(null)

  let prevContactDetail
  if (primaryContact?.previousContactDetails?.length > 0) {
    prevContactDetail = true
  }
  const columns = [
    { title: 'Address', dataIndex: 'address', key: 'address' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Suburb', dataIndex: 'suburb', key: 'suburb' },
    { title: 'Post Code', dataIndex: 'postalCode', key: 'postalCode' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Mobile #', dataIndex: 'mobileNo', key: 'mobileNo' },
    { title: 'Phone #', dataIndex: 'phoneNo', key: 'phoneNo' },
    { title: 'Work Phone #', dataIndex: 'workPhoneNo', key: 'workPhoneNo' }
  ]

  const handleModalVisible = e => {
    e.currentTarget.id === 'NewContact'
      ? setContactClick('new-contact')
      : e.currentTarget.id === 'EditContactDetail'
      ? setContactClick('edit-contact-detail')
      : setContactClick('edit-contact')
    handleVisibility(true)
  }

  const handleCreateContact = async form => {
    const setType = form.isPrimaryContact ? 'primary' : 'other'
    const formData = {
      ...form,
      type: setType,
      participantId: participantToDetails.id
    }
    setPrimaryContact(formData)
      .then(() => setContactSaved(true))
      .catch(() => setContactSaved(false))
    handleVisibility(false)
  }

  const handleEditContactDetail = async form => {
    const formData = { ...form, participantId: participantToDetails.id }
    setPrimaryContactDetail(formData)
      .then(() => setEditContactSaved(true))
      .catch(() => setContactDetailSaved(false))
    handleVisibility(false)
  }

  const handleEditContact = async form => {
    const formData = { ...form, participantId: participantToDetails.id }
    setEditPrimaryContact(formData)
      .then(() => setEditContactSaved(true))
      .catch(() => setEditContactSaved(false))
    handleVisibility(false)
  }
  return (
    <div>
      <Divider orientation="left">Primary Contact Details:</Divider>
      <Button
        id="NewContact"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Add a Primary Contact
      </Button>
      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={750}
        minHeight={1600}
        title={
          contactClick === 'new-contact'
            ? 'Add A New Primary Contact'
            : contactClick === 'edit-contact-detail'
            ? 'Edit A Primary Contact Detail'
            : 'Edit Primary Contact'
        }
        destroyOnClose
      >
        {contactClick === 'new-contact' ? (
          <PrimaryContactsForm
            data={primaryContact}
            onContactSubmit={handleCreateContact}
          />
        ) : contactClick === 'edit-contact-detail' ? (
          <PrimaryContactDetailForm
            data={primaryContact}
            onContactDetailSubmit={handleEditContactDetail}
          />
        ) : (
          <PrimaryContactEditForm
            data={primaryContact}
            onContactEditSubmit={handleEditContact}
          />
        )}
      </Modal>
      <Descriptions>
        <Descriptions.Item label="First Name">
          {primaryContact?.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {primaryContact?.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Relationship">
          {primaryContact?.relationship}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {primaryContact?.currentContactDetails?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile#">
          {primaryContact?.currentContactDetails?.mobileNo}
        </Descriptions.Item>
        <Descriptions.Item label="Phone#">
          {primaryContact?.currentContactDetails?.phoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {primaryContact?.currentContactDetails?.address}
        </Descriptions.Item>
        <Descriptions.Item label="Post Code">
          {primaryContact?.currentContactDetails?.postalCode}
        </Descriptions.Item>
        <Descriptions.Item label="State">
          {primaryContact?.currentContactDetails?.state}
        </Descriptions.Item>
        <Descriptions.Item label="Suburb">
          {primaryContact?.currentContactDetails?.suburb}
        </Descriptions.Item>
        <Descriptions.Item label="Work Phone #">
          {primaryContact?.currentContactDetails?.workPhoneNo}
        </Descriptions.Item>
      </Descriptions>
      <Button
        id="EditContactDetail"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Edit a Primary Contact Details
      </Button>
      <span style={{ padding: '10px' }}></span>
      <Button
        id="EditContact"
        onClick={handleModalVisible}
        type="primary"
        style={{ marginBottom: 16, background: '#008385' }}
      >
        Edit a Primary Contact
      </Button>
      {prevContactDetail ? (
        <Collapse accordion>
          <Panel header="Show Previous Primary Contact Details" key="1">
            <div>
              <Descriptions>
                <Descriptions.Item>
                  <div style={{ width: '1100px' }}>
                    <PrevPrimaryContactTable
                      columns={columns}
                      data={primaryContact?.previousContactDetails}
                      rowKey="id"
                      size="small"
                    />
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Panel>
        </Collapse>
      ) : (
        <div> </div>
      )}
    </div>
  )
}
