import React, { useState } from 'react'
import { Divider, Descriptions, Button, Tabs, Popconfirm } from 'antd'
import Modal from '@components/modals'
import ParticipantSurveyStatusForm from '@components/forms/contactStatus'
import ParentSurveyStatusForm from '@components/forms/contactStatus'
import RecallingSentenceSurveyStatusForm from '@components/forms/contactStatus'
import ParticipantMP1SurveyStatusForm from '@components/forms/contactStatus'
import ParticipantMP2SurveyStatusForm from '@components/forms/contactStatus'
import ParentMP1SurveyStatusForm from '@components/forms/contactStatus'
import ParentMP2SurveyStatusForm from '@components/forms/contactStatus'
import useModal from '@src/hooks/useModal'
import moment from 'moment'
import classNames from 'classnames'
const { TabPane } = Tabs

export default function ConsentContainer (props) {
  const {
    participantConsentDetails,
    parentConsentDetails,
    parentMp1Details,
    parentMp2Details,
    participantMp1Details,
    participantMp2Details,
    recallingSentenceDetails,
    updateParticipantContactStatus,
    updateParentContactStatus,
    updateRecallingSentenceContactStatus,
    updateParentMP1ContactStatus,
    updateParentMP2ContactStatus,
    updateParticipantMP1ContactStatus,
    updateParticipantMP2ContactStatus,
    deleteParticipantConsentSurvey,
    deleteParentConsentSurvey,
    deleteRecallingSentenceTask,
    deleteParentMP1ConsentSurvey,
    deleteParentMP2ConsentSurvey,
    deleteParticipantMP1ConsentSurvey,
    deleteParticipantMP2ConsentSurvey
  } = props

  const { visible, handleVisibility } = useModal()
  const [participantContactStatusSaved, setParticipantContactStatusSaved] = useState(
    false
  )
  const [parentContactStatusSaved, setParentContactStatusSaved] = useState(false)
  const [
    recallingSentenceContactStatusSaved,
    setRecallingSentenceContactStatusSaved
  ] = useState(false)
  const [parentMP1ContactStatusSaved, setParentMP1ContactStatusSaved] = useState(
    false
  )
  const [
    participantMP1ContactStatusSaved,
    setParticipantMP1ContactStatusSaved
  ] = useState(false)

  const [editStatusButtonClick, setEditStatusButtonClick] = useState('')

  const handleModalVisible = (e) => {
    e.currentTarget.id === 'ParticipantUpdateStatus'
      ? setEditStatusButtonClick('participant')
      : e.currentTarget.id === 'ParentUpdateStatus'
        ? setEditStatusButtonClick('parent')
        : e.currentTarget.id === 'ParentMP1UpdateStatus'
          ? setEditStatusButtonClick('parentMP1')
          : e.currentTarget.id === 'ParentMP2UpdateStatus'
            ? setEditStatusButtonClick('parentMP2')
            : e.currentTarget.id === 'ParticipantMP1UpdateStatus'
              ? setEditStatusButtonClick('participantMP1')
              : e.currentTarget.id === 'ParticipantMP2UpdateStatus'
                ? setEditStatusButtonClick('participantMP2')
                : setEditStatusButtonClick('recallingSentence')
    handleVisibility(true)
  }
  const handleParticipantContactStatusEdit = async (form) => {
    updateParticipantContactStatus(form)
      .then(() => setParticipantContactStatusSaved(true))
      .catch(() => setParticipantContactStatusSaved(false))
    handleVisibility(false)
  }

  const handleParentContactStatusEdit = async (form) => {
    updateParentContactStatus(form)
      .then(() => setParentContactStatusSaved(true))
      .catch(() => setParentContactStatusSaved(false))
    handleVisibility(false)
  }

  const handleRecallingSentenceContactStatusEdit = async (form) => {
    updateRecallingSentenceContactStatus(form)
      .then(() => setRecallingSentenceContactStatusSaved(true))
      .catch(() => setRecallingSentenceContactStatusSaved(false))
    handleVisibility(false)
  }

  const handleParentMP1ContactStatusEdit = async (form) => {
    updateParentMP1ContactStatus(form)
      .then(() => setParentMP1ContactStatusSaved(true))
      .catch(() => setParentMP1ContactStatusSaved(false))
    handleVisibility(false)
  }

  const handleParentMP2ContactStatusEdit = async (form) => {
    updateParentMP2ContactStatus(form)
    handleVisibility(false)
  }

  const handleParticipantMP1ContactStatusEdit = async (form) => {
    updateParticipantMP1ContactStatus(form)
      .then(() => setParticipantMP1ContactStatusSaved(true))
      .catch(() => setParticipantMP1ContactStatusSaved(false))
    handleVisibility(false)
  }

  const handleParticipantMP2ContactStatusEdit = async (form) => {
    updateParticipantMP2ContactStatus(form)
    handleVisibility(false)
  }

  const callback = (key) => {
    // console.log(key)
  }

  const confirmParentContactDelete = (e) => {
    deleteParentConsentSurvey(parentConsentDetails?.surveyBase.id)
  }

  const confirmParticipantContactDelete = (e) => {
    deleteParticipantConsentSurvey(participantConsentDetails?.surveyBase.id)
  }

  const confirmRecallingContactDelete = (e) => {
    deleteRecallingSentenceTask(recallingSentenceDetails?.surveyBase.id)
  }

  const confirmParentMP1ContactDelete = (e) => {
    deleteParentMP1ConsentSurvey(parentMp1Details?.surveyBase.id)
  }

  const confirmParentMP2ContactDelete = (e) => {
    deleteParentMP2ConsentSurvey(parentMp1Details?.surveyBase.id)
  }

  const confirmParticipantMP1ContactDelete = (e) => {
    deleteParticipantMP1ConsentSurvey(participantMp1Details?.surveyBase.id)
  }

  const confirmParticipantMP2ContactDelete = (e) => {
    deleteParticipantMP2ConsentSurvey(participantMp2Details?.surveyBase.id)
  }
  const cancel = (e) => {
    // message.error('Click on No')
  }
  return (
    <div>
      <Tabs onChange={callback} type='card'>
        <TabPane tab='Parent Consent Survey' key='1'>
          <Descriptions>
            <Descriptions.Item label='Survey Response ID'>
              {' '}
              {parentConsentDetails?.surveyBase?.responseId}
            </Descriptions.Item>
            <Descriptions.Item label='Project Consent Status'>
              <span
                className={classNames(
                  'participant-badge',
                  'status-' + `${parentConsentDetails?.projectConsent}`
                )}
              >
                {' '}
                {parentConsentDetails?.projectConsent}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label='Teen Contact Status'>
              <span
                className={classNames(
                  'participant-badge',
                  'status-' + `${parentConsentDetails?.teenContactConsent}`
                )}
              >
                {parentConsentDetails?.teenContactConsent}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label='Invitation Sent'>
              {parentConsentDetails?.surveyBase?.invitationDate !== null &&
              parentConsentDetails?.surveyBase?.invitationDate !== undefined
                ? moment(parentConsentDetails?.surveyBase?.invitationDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {parentConsentDetails?.surveyBase?.emailDate !== null &&
              parentConsentDetails?.surveyBase?.emailDate !== undefined
                ? moment(parentConsentDetails?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {parentConsentDetails?.surveyBase?.smsDate !== null &&
              parentConsentDetails?.surveyBase?.smsDate !== undefined
                ? moment(parentConsentDetails?.surveyBase?.smsDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {parentConsentDetails?.surveyBase?.callDate !== null &&
              parentConsentDetails?.surveyBase?.callDate !== undefined
                ? moment(parentConsentDetails?.surveyBase?.callDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {parentConsentDetails?.surveyBase?.endDate !== null &&
              parentConsentDetails?.surveyBase?.endDate !== undefined
                ? moment(parentConsentDetails?.surveyBase?.endDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label=''></Descriptions.Item>
            <Descriptions.Item label='Survey Link'>
              {' '}
              {parentConsentDetails?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {parentConsentDetails !== null ? (
            <>
              <Button
                id='ParentUpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Parent Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmParentContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Parent Consent Survey
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
          <Divider orientation='left'>Participant's Information:</Divider>
          <Descriptions>
            <Descriptions.Item label='Participant Email'>
              {' '}
              {parentConsentDetails?.participantEmail}
            </Descriptions.Item>
            <Descriptions.Item label='Participant Name'>
              {parentConsentDetails?.participantName}
            </Descriptions.Item>
            <Descriptions.Item label='Participant School Name'>
              {parentConsentDetails?.schoolName}
            </Descriptions.Item>
            <Descriptions.Item label='Participant School State'>
              {parentConsentDetails?.schoolState}
            </Descriptions.Item>
            <Descriptions.Item label='Participant School Suburb'>
              {parentConsentDetails?.schoolSuburb}
            </Descriptions.Item>
            <Descriptions.Item label='Participant School Year'>
              {parentConsentDetails?.schoolYearLevel}
            </Descriptions.Item>
            <Descriptions.Item label='Participant School Status'>
              {parentConsentDetails?.schoolStatus}
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab='Participant Consent Survey' key='2'>
          <Descriptions>
            <Descriptions.Item label='Survey Response ID'>
              {' '}
              {participantConsentDetails?.surveyBase?.responseId}
            </Descriptions.Item>
            <Descriptions.Item label='Project Consent Status'>
              <span
                className={classNames(
                  'participant-badge',
                  'status-' + `${participantConsentDetails?.projectConsent}`
                )}
              >
                {participantConsentDetails?.projectConsent}{' '}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label='VTAC Consent Status'>
              <span
                className={classNames(
                  'participant-badge',
                  'status-' + `${participantConsentDetails?.vtacConsent}`
                )}
              >
                {participantConsentDetails?.vtacConsent}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label='Need Help Status'>
              <span
                className={classNames(
                  'participant-badge',
                  'status-' + `${participantConsentDetails?.assistConsent}`
                )}
              >
                {participantConsentDetails?.assistConsent}{' '}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label='Invitation Sent'>
              {participantConsentDetails?.surveyBase?.invitationDate !== null &&
              participantConsentDetails?.surveyBase?.invitationDate !== undefined
                ? moment(
                    participantConsentDetails?.surveyBase?.invitationDate
                ).format('DD-MM-YYYY')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {participantConsentDetails?.surveyBase?.emailDate !== null &&
              participantConsentDetails?.surveyBase?.emailDate !== undefined
                ? moment(participantConsentDetails?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {participantConsentDetails?.surveyBase?.smsDate !== null &&
              participantConsentDetails?.surveyBase?.smsDate !== undefined
                ? moment(participantConsentDetails?.surveyBase?.smsDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {participantConsentDetails?.surveyBase?.callDate !== null &&
              participantConsentDetails?.surveyBase?.callDate !== undefined
                ? moment(participantConsentDetails?.surveyBase?.callDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {participantConsentDetails?.surveyBase?.endDate !== null &&
              participantConsentDetails?.surveyBase?.endDate !== undefined
                ? moment(participantConsentDetails?.surveyBase?.endDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Survey Link'>
              {' '}
              {participantConsentDetails?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {participantConsentDetails !== null ? (
            <>
              <Button
                id='ParticipantUpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Participant Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmParticipantContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Participant Consent Survey
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
        </TabPane>
        <TabPane tab='Recalling Sentences Task' key='3'>
          <Descriptions>
            <Descriptions.Item label='Invitation Sent'>
              {recallingSentenceDetails?.surveyBase?.invitationDate !== null &&
              recallingSentenceDetails?.surveyBase?.invitationDate !== undefined
                ? moment(
                    recallingSentenceDetails?.surveyBase?.invitationDate
                ).format('DD-MM-YYYY')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {recallingSentenceDetails?.surveyBase?.emailDate !== null &&
              recallingSentenceDetails?.surveyBase?.emailDate !== undefined
                ? moment(recallingSentenceDetails?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {recallingSentenceDetails?.surveyBase?.smsDate !== null &&
              recallingSentenceDetails?.surveyBase?.smsDate !== undefined
                ? moment(recallingSentenceDetails?.surveyBase?.smsDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {recallingSentenceDetails?.surveyBase?.callDate !== null &&
              recallingSentenceDetails?.surveyBase?.callDate !== undefined
                ? moment(recallingSentenceDetails?.surveyBase?.callDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {recallingSentenceDetails?.surveyBase?.endDate !== null &&
              recallingSentenceDetails?.surveyBase?.endDate !== undefined
                ? moment(recallingSentenceDetails?.surveyBase?.endDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Recalling Sentences ID'>
              {' '}
              {recallingSentenceDetails?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {recallingSentenceDetails !== null ? (
            <>
              <Button
                id='RecallingSentenceUpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Recalling Sentence Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmRecallingContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Recalling Sentences Task
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
        </TabPane>
        <TabPane tab='Parent MP 1 Survey' key='4'>
          <Descriptions>
            <Descriptions.Item label='Survey Response ID'>
              {' '}
              {parentMp1Details?.surveyBase?.responseId}
            </Descriptions.Item>
            <Descriptions.Item label='Invitation Sent'>
              {parentMp1Details?.surveyBase?.invitationDate !== null &&
              parentMp1Details?.surveyBase?.invitationDate !== undefined
                ? moment(parentMp1Details?.surveyBase?.invitationDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {parentMp1Details?.surveyBase?.emailDate !== null &&
              parentMp1Details?.surveyBase?.emailDate !== undefined
                ? moment(parentMp1Details?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {parentMp1Details?.surveyBase?.smsDate !== null &&
              parentMp1Details?.surveyBase?.smsDate !== undefined
                ? moment(parentMp1Details?.surveyBase?.smsDate).format('DD-MM-YYYY')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {parentMp1Details?.surveyBase?.callDate !== null &&
              parentMp1Details?.surveyBase?.callDate !== undefined
                ? moment(parentMp1Details?.surveyBase?.callDate).format('DD-MM-YYYY')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {parentMp1Details?.surveyBase?.endDate !== null &&
              parentMp1Details?.surveyBase?.endDate !== undefined
                ? moment(parentMp1Details?.surveyBase?.endDate).format('DD-MM-YYYY')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Survey Link'>
              {' '}
              {parentMp1Details?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {parentMp1Details !== null ? (
            <>
              <Button
                id='ParentMP1UpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Parent Measure Package 1 Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmParentMP1ContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Parent Measure Package 1 Survey
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
        </TabPane>
        <TabPane tab='Participant MP 1 Survey' key='5'>
          <Descriptions>
            <Descriptions.Item label='Survey Response ID'>
              {' '}
              {participantMp1Details?.surveyBase?.responseId}
            </Descriptions.Item>
            <Descriptions.Item label='Invitation Sent'>
              {participantMp1Details?.surveyBase?.invitationDate !== null &&
              participantMp1Details?.surveyBase?.invitationDate !== undefined
                ? moment(participantMp1Details?.surveyBase?.invitationDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {participantMp1Details?.surveyBase?.emailDate !== null &&
              participantMp1Details?.surveyBase?.emailDate !== undefined
                ? moment(participantMp1Details?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {participantMp1Details?.surveyBase?.smsDate !== null &&
              participantMp1Details?.surveyBase?.smsDate !== undefined
                ? moment(participantMp1Details?.surveyBase?.smsDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {participantMp1Details?.surveyBase?.callDate !== null &&
              participantMp1Details?.surveyBase?.callDate !== undefined
                ? moment(participantMp1Details?.surveyBase?.callDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {participantMp1Details?.surveyBase?.endDate !== null &&
              participantMp1Details?.surveyBase?.endDate !== undefined
                ? moment(participantMp1Details?.surveyBase?.endDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Survey Link'>
              {' '}
              {participantMp1Details?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {participantMp1Details !== null ? (
            <>
              <Button
                id='ParticipantMP1UpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Participant Measure Package 1 Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmParticipantMP1ContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Participant Measure Package 1 Survey
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
        </TabPane>
        <TabPane tab='Participant MP 2 Survey' key='6'>
          <Descriptions>
            <Descriptions.Item label='Survey Response ID'>
              {participantMp2Details?.surveyBase?.responseId}
            </Descriptions.Item>
            <Descriptions.Item label='Invitation Sent'>
              {participantMp2Details?.surveyBase?.invitationDate !== null &&
              participantMp2Details?.surveyBase?.invitationDate !== undefined
                ? moment(participantMp2Details?.surveyBase?.invitationDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Email Sent'>
              {participantMp2Details?.surveyBase?.emailDate !== null &&
              participantMp2Details?.surveyBase?.emailDate !== undefined
                ? moment(participantMp2Details?.surveyBase?.emailDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder SMS Sent'>
              {participantMp2Details?.surveyBase?.smsDate !== null &&
              participantMp2Details?.surveyBase?.smsDate !== undefined
                ? moment(participantMp2Details?.surveyBase?.smsDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Reminder Call Made'>
              {participantMp2Details?.surveyBase?.callDate !== null &&
              participantMp2Details?.surveyBase?.callDate !== undefined
                ? moment(participantMp2Details?.surveyBase?.callDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Completion Date'>
              {participantMp2Details?.surveyBase?.endDate !== null &&
              participantMp2Details?.surveyBase?.endDate !== undefined
                ? moment(participantMp2Details?.surveyBase?.endDate).format(
                  'DD-MM-YYYY'
                )
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label='Survey Link'>
              {participantMp2Details?.surveyBase?.link}
            </Descriptions.Item>
          </Descriptions>
          {participantMp2Details !== null ? (
            <>
              <Button
                id='ParticipantMP2UpdateStatus'
                onClick={handleModalVisible}
                type='primary'
                style={{ marginBottom: 16, background: '#008385' }}
              >
                Edit Participant Measure Package 2 Contact Status
              </Button>
              <span style={{ paddingRight: '15px' }} />
              <Popconfirm
                style={{ marginBottom: 16, background: '#008385' }}
                title='Are you sure to delete ?'
                onConfirm={confirmParticipantMP2ContactDelete}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  style={{ marginBottom: 16, background: '#008385' }}
                >
                  Delete Participant Measure Package 2 Survey
                </Button>
              </Popconfirm>
            </>
          ) : (
            ''
          )}
        </TabPane>
      </Tabs>
      <Modal
        visible={visible}
        handleVisibility={handleVisibility}
        width={550}
        minHeight={200}
        title={
          editStatusButtonClick === 'participant'
            ? 'Edit Participant Contact Status'
            : editStatusButtonClick === 'parent'
              ? 'Edit Parent Contact Status'
              : editStatusButtonClick === 'participantMP1'
                ? 'Edit Participant Measure Package 1 Contact Status'
                : editStatusButtonClick === 'parentMP1'
                  ? 'Edit Parent Measure Package 1 Contact Status'
                  : editStatusButtonClick === 'participantMP2'
                    ? 'Edit Parent Measure Package 2 Contact Status'
                    : 'Edit Recalling Sentence Contact Status'
        }
        destroyOnClose
      >
        {editStatusButtonClick === 'participant' ? (
          <ParticipantSurveyStatusForm
            data={participantConsentDetails?.surveyBase}
            onSurveyStatusSubmit={handleParticipantContactStatusEdit}
          />
        ) : editStatusButtonClick === 'parent' ? (
          <ParentSurveyStatusForm
            data={parentConsentDetails?.surveyBase}
            onSurveyStatusSubmit={handleParentContactStatusEdit}
          />
        ) : editStatusButtonClick === 'participantMP1' ? (
          <ParticipantMP1SurveyStatusForm
            data={participantMp1Details?.surveyBase}
            onSurveyStatusSubmit={handleParticipantMP1ContactStatusEdit}
          />
        ) : editStatusButtonClick === 'participantMP2' ? (
          <ParticipantMP2SurveyStatusForm
            data={participantMp2Details?.surveyBase}
            onSurveyStatusSubmit={handleParticipantMP2ContactStatusEdit}
          />
        ) : editStatusButtonClick === 'parentMP1' ? (
          <ParentMP1SurveyStatusForm
            data={parentMp1Details?.surveyBase}
            onSurveyStatusSubmit={handleParentMP1ContactStatusEdit}
          />
        ) : editStatusButtonClick === 'parentMP2' ? (
          <ParentMP2SurveyStatusForm
            data={parentMp2Details?.surveyBase}
            onSurveyStatusSubmit={handleParentMP2ContactStatusEdit}
          />
        ) : (
          <RecallingSentenceSurveyStatusForm
            data={recallingSentenceDetails?.surveyBase}
            onSurveyStatusSubmit={handleRecallingSentenceContactStatusEdit}
          />
        )}
      </Modal>
    </div>
  )
}
