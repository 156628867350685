export const USER = {
  SET_LOGIN_USER: 'USER.SET_LOGIN_USER',
  SET_USER_FULFILLED: 'USER.SET_USER_FULFILLED',
  SET_LOGOUT_USER: 'USER.SET_LOGOUT_USER',
  REGISTER_START: 'USER.REGISTER_START',
  SET_ALL_USERS: 'USER.SET_ALL_USERS',
  SET_NEW_USER: 'USER.SET_NEW_USER',
  UPDATE_USER_DETAIL: 'USER.UPDATE_PARTICIPANT_COMMENT',
  UPDATE_USER_OWN_PASSWORD: 'USER.UPDATE_USER_OWN_PASSWORD',
  UPDATE_OTHER_USER_PASSWORD: 'USER.UPDATE_OTHER_USER_PASSWORD',
  SET_DOWNLOAD_BULK_WORKSHEET: 'USER.SET_DOWNLOAD_BULK_WORKSHEET',
}

export const DASHBOARD = {
  SET_STATS: 'DASHBOARD.SET_STATS',
}

export const PARTICIPANT = {
  SET_PARTICIPANTS_PAGE: 'PARTICIPANT.SET_PARTICIPANTS_PAGE',
  SET_PARTICIPANTS: 'PARTICIPANT.SET_PARTICIPANTS',
  SET_PARTICIPANT_CONSENTS: 'PARTICIPANT.SET_PARTICIPANT_CONSENTS',
  SET_PARTICIPANTS_TO_DETAILS: 'PARTICIPANT.SET_PARTICIPANTS_TO_DETAILS',
  SET_PARTICIPANT: 'PARTICIPANT.SET_PARTICIPANT',
  UPDATE_PARTICIPANT: 'PARTICIPANT.UPDATE_PARTICIPANT',
  SET_PARTICIPANT_COMMENT: 'PARTICIPANT.SET_PARTICIPANT_COMMENT',
  UPDATE_PARTICIPANT_COMMENT: 'PARTICIPANT.UPDATE_PARTICIPANT_COMMENT',
  DELETE_PARTICIPANT_COMMENT: 'PARTICIPANT.DELETE_PARTICIPANT_COMMENT',
  SET_PARTICIPANT_ACTION: 'PARTICIPANT.SET_PARTICIPANT_ACTION',
  UPDATE_NO_OF_ACTIONS_DUE: 'PARTICIPANT.UPDATE_NO_OF_ACTIONS_DUE',
  UPDATE_PARTICIPANT_ACTION: 'PARTICIPANT.UPDATE_PARTICIPANT_ACTION',
  UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE:
    'PARTICIPANT.UPDATE_PARTICIPANT_ACTION_MARK_COMPLETE',
  SET_PARTICIPANT_OTHER_CONTACT: 'PARTICIPANT.SET_PARTICIPANT_OaTHER_CONTACT',
  SET_PARTICIPANT_OTHER_CONTACT_DETAIL:
    'PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_DETAIL',
  SET_PARTICIPANT_PRIMARY_CONTACT: 'PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT',
  SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL:
    'PARTICIPANT.SET_PARTICIPANT_PRIMARY_CONTACT_DETAIL',
  SET_PARTICIPANT_EDIT_PRIMARY_CONTACT:
    'PARTICIPANT.SET_PARTICIPANT_EDIT_PRIMARY_CONTACT',
  SET_PARTICIPANT_EDIT_OTHER_CONTACT:
    'PARTICIPANT.SET_PARTICIPANT_EDIT_OTHER_CONTACT',
  UPDATE_PARTICIPANT_CONTACT_DETAIL: 'PARTICIPANT.UPDATE_PARTICIPANT_CONTACT_DETAIL',
  SET_PARTICIPANT_OTHER_CONTACT_PRIMARY:
    'PARTICIPANT.SET_PARTICIPANT_OTHER_CONTACT_PRIMARY',
  SET_PARTICIPANT_SCHOOL_DETAIL: 'PARTICIPANT.SET_PARTICIPANT_SCHOOL_DETAIL',
  UPDATE_PARTICIPANT_DETAIL: 'PARTICIPANT.UPDATE_PARTICIPANT_DETAIL',
  SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY:
    'PARTICIPANT.SET_UPLOAD_PARTICIPANT_DISTRIBUTION_HISTORY',
  SET_UPLOAD_PARTICIPANT_CONSENT: 'PARTICIPANT.SET_UPLOAD_PARTICIPANT_CONSENT',
  SET_UPLOAD_PARTICIPANT_CORR_HISTORY:
    'PARTICIPANT.SET_UPLOAD_PARTICIPANT_CORR_HISTORY',
  UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS:
    'PARTICIPANT.UPDATE_PARTICIPANT_SURVEY_CONTACT_STATUS',
  DELETE_PARTICIPANT_CONSENT_SURVEY: 'PARTICIPANT.DELETE_PARTICIPANT_CONSENT_SURVEY',
  SET_PARTICIPANT_DOWNLOAD_FILE: 'PARTICIPANT.SET_PARTICIPANT_DOWNLOAD_FILE',
}

export const PARENT = {
  // SET_PARTICIPANTS_TO_DETAILS: 'PARENT.SET_PARTICIPANTS_TO_DETAILS',
  SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY:
    'PARENT.SET_UPLOAD_PARENT_DISTRIBUTION_HISTORY',
  SET_PARENT_CONSENTS: 'PARENT.SET_PARENT_CONSENTS',
  SET_UPLOAD_PARENT_CONSENT: 'PARENT.SET_UPLOAD_PARENT_CONSENT',
  SET_UPLOAD_PARENT_CORR_HISTORY: 'PARENT.SET_UPLOAD_PARENT_CORR_HISTORY',
  UPDATE_PARENT_SURVEY_CONTACT_STATUS: 'PARENT.UPDATE_PARENT_SURVEY_CONTACT_STATUS',
  DELETE_PARENT_CONSENT_SURVEY: 'PARENT.DELETE_PARENT_CONSENT_SURVEY',
  SET_PARENT_DOWNLOAD_FILE: 'PARENT.SET_PARENT_DOWNLOAD_FILE',
}

export const RECALLINGSENTENCE = {
  SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION:
    'RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_DISTRIBUTION',
  SET_UPLOAD_RECALLING_SENTENCE_RESPONSE:
    'RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_RESPONSE',
  SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY:
    'RECALLINGSENTENCE.SET_UPLOAD_RECALLING_SENTENCE_COR_HISTORY',
  SET_RECALLING_SENTENCES: 'RECALLINGSENTENCE.SET_RECALLING_SENTENCES',
  UPDATE_RECALLING_SENTENCE_CONTACT_STATUS:
    'RECALLINGSENTENCE.UPDATE_RECALLING_SENTENCE_CONTACT_STATUS',
  DELETE_RECALLING_SENTENCE_TASK: 'RECALLINGSENTENCE.DELETE_RECALLING_SENTENCE_TASK',
  SET_RECALL_SENTENCE_DOWNLOAD_FILE:
    'RECALLINGSENTENCE.SET_RECALL_SENTENCE_DOWNLOAD_FILE',
}

export const PARENT_MP1 = {
  SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY:
    'PARENT_MP1.SET_UPLOAD_PARENT_MP1_DISTRIBUTION_HISTORY',
  SET_PARENT_MP1_CONSENTS: 'PARENT_MP1.SET_PARENT_MP1_CONSENTS',
  SET_UPLOAD_PARENT_MP1_CONSENT: 'PARENT_MP1.SET_UPLOAD_PARENT_MP1_CONSENT',
  SET_UPLOAD_PARENT_MP1_CORR_HISTORY:
    'PARENT_MP1.SET_UPLOAD_PARENT_MP1_CORR_HISTORY',
  UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS:
    'PARENT_MP1.UPDATE_PARENT_MP1_SURVEY_CONTACT_STATUS',
  DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY:
    'PARENT_MP1.DELETE_PARENT_MP1_SURVEY_CONSENT_SURVEY',
  SET_PARENT_MP1_DOWNLOAD_FILE: 'PARENT_MP1.SET_PARENT_MP1_DOWNLOAD_FILE',
}

export const PARTICIPANT_MP1 = {
  SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY:
    'PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_DISTRIBUTION_HISTORY',
  SET_PARTICIPANT_MP1_CONSENTS: 'PARTICIPANT_MP1.SET_PARTICIPANT_MP1_CONSENTS',
  SET_UPLOAD_PARTICIPANT_MP1_CONSENT:
    'PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CONSENT',
  SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY:
    'PARTICIPANT_MP1.SET_UPLOAD_PARTICIPANT_MP1_CORR_HISTORY',
  UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS:
    'PARTICIPANT_MP1.UPDATE_PARTICIPANT_MP1_SURVEY_CONTACT_STATUS',
  DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY:
    'PARTICIPANT_MP1.DELETE_PARTICIPANT_MP1_SURVEY_CONSENT_SURVEY',
  SET_PARTICIPANT_MP1_DOWNLOAD_FILE:
    'PARTICIPANT_MP1.SET_PARTICIPANT_MP1_DOWNLOAD_FILE',
}

export const PARENT_MP2 = {
  SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY:
    'PARENT_MP2.SET_UPLOAD_PARENT_MP2_DISTRIBUTION_HISTORY',
  SET_PARENT_MP2_CONSENTS: 'PARENT_MP2.SET_PARENT_MP2_CONSENTS',
  SET_UPLOAD_PARENT_MP2_CONSENT: 'PARENT_MP2.SET_UPLOAD_PARENT_MP2_CONSENT',
  SET_UPLOAD_PARENT_MP2_CORR_HISTORY:
    'PARENT_MP2.SET_UPLOAD_PARENT_MP2_CORR_HISTORY',
  UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS:
    'PARENT_MP2.UPDATE_PARENT_MP2_SURVEY_CONTACT_STATUS',
  DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY:
    'PARENT_MP2.DELETE_PARENT_MP2_SURVEY_CONSENT_SURVEY',
  SET_PARENT_MP2_DOWNLOAD_FILE: 'PARENT_MP2.SET_PARENT_MP2_DOWNLOAD_FILE',
}

export const PARTICIPANT_MP2 = {
  SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY:
    'PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_DISTRIBUTION_HISTORY',
  SET_PARTICIPANT_MP2_CONSENTS: 'PARTICIPANT_MP2.SET_PARTICIPANT_MP2_CONSENTS',
  SET_UPLOAD_PARTICIPANT_MP2_CONSENT:
    'PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CONSENT',
  SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY:
    'PARTICIPANT_MP2.SET_UPLOAD_PARTICIPANT_MP2_CORR_HISTORY',
  UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS:
    'PARTICIPANT_MP2.UPDATE_PARTICIPANT_MP2_SURVEY_CONTACT_STATUS',
  DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY:
    'PARTICIPANT_MP2.DELETE_PARTICIPANT_MP2_SURVEY_CONSENT_SURVEY',
  SET_PARTICIPANT_MP2_DOWNLOAD_FILE:
    'PARTICIPANT_MP2.SET_PARTICIPANT_MP2_DOWNLOAD_FILE',
}

export const GENERAL = {
  RESTORE_STATE: 'GENERAL.RESTORE_STATE',
}
