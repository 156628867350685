import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 100px;
  }
`

const layout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 16
  }
}
export default function UserResetPasswordForm(props) {
  const {
    onUpdateResetPasswordSubmit,
    onResetPasswordSubmitCancel,
    data,
    newPassword
  } = props

  const [copied, setCopied] = useState(false)
  const [form] = Form.useForm()
  const onCancelClick = async e => {
    onResetPasswordSubmitCancel()
  }

  const handleFinish = values => {
    onUpdateResetPasswordSubmit(values)
  }
  return (
    <div>
      <div> Are You sure to reset other User password? </div>
      <div style={{ fontWeight: 'bold', fontSize: '20px', padding: '10px' }}>
        {' '}
        {newPassword}{' '}
      </div>
      {newPassword !== null ? (
        <CopyToClipboard
          text={newPassword}
          onCopy={() => setCopied({ copied: true })}
        >
          <button
            style={{
              backgroundColor: '#008385',
              borderColor: '#1890ff',
              color: 'white'
            }}
          >
            Copy to Clipboard{' '}
          </button>
        </CopyToClipboard>
      ) : (
        ''
      )}
      <Form
        form={form}
        {...layout}
        name="nest-messages"
        onFinish={handleFinish}
        initialValues={{
          id: data?.id
        }}
      >
        <Form.Item name="id" label="id" hidden>
          <Input disabled />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} shouldUpdate>
          {() => (
            <ButtonContainer>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <span style={{ paddingLeft: '20px' }}>
                <Button
                  style={{
                    backgroundColor: '#008385',
                    color: 'white'
                  }}
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
              </span>
            </ButtonContainer>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
