import { Table } from 'antd'
import React from 'react'

export default function TableComponent(props) {
  const {
    data,
    columns,
    rowKey,
    onRowClick,
    size,
    expandedRowRender,
    tableLayout
  } = props
  return (
    <Table
      dataSource={data?.length > 0 ? data : []}
      rowKey={rowKey || 'id'}
      rowClassName={(record, index) =>
        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
      }
      // pagination={{ showSizeChanger: true }}
      expandedRowRender={expandedRowRender}
      columns={columns}
      size={size}
      tableLayout={tableLayout || 'auto'}
      onRow={onRowClick}
    />
  )
}
