import { Form, Input, Button, Select, DatePicker } from 'antd'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #008385;
    width: 150px;
  }
`

const layout = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 16
  }
}

function ActionForm(props) {
  const { onActionsSubmit, data } = props

  const handleFinish = values => {
    const modDate = values.dueDate ? moment(values.dueDate).format('YYYY-MM-DD') : null
    const modValues = {
      ...values,
      dueDate: modDate
    }
    onActionsSubmit(modValues)
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  }

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={handleFinish}
      validateMessages={validateMessages}
      initialValues={{
        id: data?.id,
        note: data?.note,
        dueDate: data?.dueDate
      }}
    >
      <Form.Item
        label="Due Date"
        name={['dueDate']}
      >
        <DatePicker format={'DD-MM-YYYY'} />
      </Form.Item>
      <Form.Item
        name={['note']}
        label="Note"
        rules={[
          {
            required: true,
            // initialValue: data && data?.note
          }
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <ButtonContainer>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </ButtonContainer>
      </Form.Item>
    </Form>
  )
}
export default ActionForm
