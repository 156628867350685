import { Layout, Menu } from 'antd'
import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ROUTES from '@constants/routes'
import style from './style.module.less'
import {
  UnorderedListOutlined,
  CommentOutlined,
  ReconciliationOutlined,
  RightOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  SyncOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'

const { Sider } = Layout

function AppSidebar(props) {
  const [menuCollapsed, setMenuCollapsed] = useState(false)
  const renderAdminMenu = () => {
    return (
      <Menu
        className={style.appMenu}
        mode="inline"
        selectedKeys={[props.history.location.pathname]}
        theme="light"
      >
        <Menu.Item key={ROUTES.dashboard}>
          <Link to={ROUTES.dashboard}>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.participantList}>
          <Link to={ROUTES.participantList}>
            <UnorderedListOutlined />
            <span>Participants List</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.parentConsent}>
          <Link to={ROUTES.parentConsent}>
            <CommentOutlined />
            <span>Parents Consents</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.participantConsent}>
          <Link to={ROUTES.participantConsent}>
            <FileSyncOutlined />
            <span>Participants Consents</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.recallingSentence}>
          <Link to={ROUTES.recallingSentence}>
            <span>
              <SyncOutlined />
              <span>Recalling Sentences</span>
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.parentMPOne}>
          <Link to={ROUTES.parentMPOne}>
            <span>
              <ScheduleOutlined />
              <span>Parents MP1 </span>
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.participantMPOne}>
          <Link to={ROUTES.participantMPOne}>
            <span>
              <ReconciliationOutlined />
              <span>Participants MP1 </span>
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.participantMPTwo}>
          <Link to={ROUTES.participantMPTwo}>
            <span>
              <ReconciliationOutlined />
              <span>Participants MP2 </span>
            </span>
          </Link>
        </Menu.Item>
      </Menu>
    )
  }
  const CustomTrigger = () => (
    <div className={style.customTrigger}>
      <RightOutlined />
    </div>
  )

  return (
    <Sider
      className={style.appSidebar}
      collapsed={menuCollapsed}
      trigger={<CustomTrigger />}
      style={{ background: '#008385' }}
      collapsible
      onCollapse={() => setMenuCollapsed(!menuCollapsed)}
    >
      {renderAdminMenu()}
    </Sider>
  )
}

export default withRouter(AppSidebar)
