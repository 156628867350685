import React, { useEffect } from 'react'
import {
  getParticipant,
  getParticipantConsents,
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  setParticipantConsent,
  setParticipantCorrespondenceHistory,
  updateParticipantContactStatus,
  deleteParticipantConsentSurvey,
  getParticipantConsentWorkSheetDownload
} from '@actions/participantActions'

import { updateRecallingSentenceContactStatus, deleteRecallingSentenceTask } from '@actions/recallingSentenceActions'
import { updateParentMP1ContactStatus, deleteParentMP1ConsentSurvey } from '@actions/parentMPOneActions'
import { updateParticipantMP1ContactStatus, deleteParticipantMP1ConsentSurvey } from '@actions/participantMPOneActions'
import { updateParticipantMP2ContactStatus, deleteParticipantMP2ConsentSurvey } from '@actions/participantMPTwoActions'
import { updateParentContactStatus, deleteParentConsentSurvey } from '@actions/parentActions'

import ParticipantsConsent from '@containers/participantConsent/participantConsentList'
import ParticipantDetails from '@containers/participants/details'
import connect from 'react-redux/es/connect/connect'
import useModal from '@src/hooks/useModal'
import ModalComponent from '@components/modals'
import { Spin } from 'antd'
import ConsentSummary from '@containers/participants/consents'

function ParticipantContainer(props) {
  const {
    //States
    loading,
    user,
    // participants,
    participantConsents,
    participantToDetails,
    location,
    //Dispatch
    getParticipant,
    getParticipantConsents,
    setComment,
    updateComment,
    deleteComment,
    setAction,
    updateAction,
    updateActionComplete,
    setContact,
    setContactDetail,
    setPrimaryContact,
    setPrimaryContactDetail,
    setEditPrimaryContact,
    setEditOtherContact,
    setParticipantContactDetail,
    updateContactAccess,
    setSchoolDetail,
    updateParticipantDetail,
    setParticipantConsent,
    setParticipantCorrespondenceHistory,
    updateParticipantContactStatus,
    updateParentContactStatus,
    updateRecallingSentenceContactStatus,
    updateParentMP1ContactStatus,
    updateParticipantMP1ContactStatus,
    updateParticipantMP2ContactStatus,
    deleteParticipantConsentSurvey,
    deleteParentConsentSurvey,
    deleteRecallingSentenceTask,
    deleteParentMP1ConsentSurvey,
    deleteParticipantMP1ConsentSurvey,
    deleteParticipantMP2ConsentSurvey,
    getParticipantConsentWorkSheetDownload
  } = props
  const { visible, handleVisibility } = useModal()

  useEffect(() => {
    getParticipantConsents()
  }, [getParticipantConsents])
  return (
    <div>
      <ModalComponent
        width={1200}
        visible={visible}
        handleVisibility={handleVisibility}
        title={
          participantToDetails
            ? 'Participant Details : ' +
            participantToDetails.participantId +
            ' - ' +
            participantToDetails.firstName +
            ' ' +
            participantToDetails.lastName
            : ''
        }
      >
        <ParticipantDetails
          participantToDetails={participantToDetails}
          user={user}
          setComment={setComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          setAction={setAction}
          updateAction={updateAction}
          updateActionComplete={updateActionComplete}
          setContact={setContact}
          setContactDetail={setContactDetail}
          setPrimaryContact={setPrimaryContact}
          setPrimaryContactDetail={setPrimaryContactDetail}
          setEditPrimaryContact={setEditPrimaryContact}
          setEditOtherContact={setEditOtherContact}
          setParticipantContactDetail={setParticipantContactDetail}
          updateContactAccess={updateContactAccess}
          setSchoolDetail={setSchoolDetail}
          updateParticipantDetail={updateParticipantDetail}
          updateParticipantContactStatus={updateParticipantContactStatus}
          updateParentContactStatus={updateParentContactStatus}
          updateRecallingSentenceContactStatus={updateRecallingSentenceContactStatus}
          updateParentMP1ContactStatus={updateParentMP1ContactStatus}
          updateParticipantMP1ContactStatus={updateParticipantMP1ContactStatus}
          updateParticipantMP2ContactStatus={updateParticipantMP2ContactStatus}
          deleteParticipantConsentSurvey={deleteParticipantConsentSurvey}
          deleteParentConsentSurvey={deleteParentConsentSurvey}
          deleteRecallingSentenceTask={
            deleteRecallingSentenceTask
          }
          deleteParentMP1ConsentSurvey={deleteParentMP1ConsentSurvey}
          deleteParticipantMP1ConsentSurvey={deleteParticipantMP1ConsentSurvey}
          deleteParticipantMP2ConsentSurvey={deleteParticipantMP2ConsentSurvey}
          parentComponent="participant-consents"
        />
      </ModalComponent>

      {/*{participantConsentComponent ? (*/}
        <Spin spinning={loading.uploadingSheet}>
          <ParticipantsConsent
            loading={loading}
            participantConsents={participantConsents}
            getParticipant={getParticipant}
            setParticipantConsent={setParticipantConsent}
            setParticipantCorrespondenceHistory={setParticipantCorrespondenceHistory}
            handleParticipantDetailsDisplay={handleVisibility}
            getParticipantConsentWorkSheetDownload={
              getParticipantConsentWorkSheetDownload
            }
          />
        </Spin>
    </div>
  )
}

const mapStateToProps = ({ combineReducer, user }) => ({
  participants: combineReducer.participants,
  participantConsents: combineReducer.participantConsents,
  loading: combineReducer.loading,
  participantToDetails: combineReducer.participantToDetails,
  user: user.user
})

const mapDispatchToProps = {
  // getParticipants,
  getParticipant: id => getParticipant(id),
  getParticipantConsents,
  setComment,
  updateComment,
  deleteComment,
  setAction,
  updateAction,
  updateActionComplete,
  setContact,
  setContactDetail,
  setPrimaryContact,
  setPrimaryContactDetail,
  setEditPrimaryContact,
  setEditOtherContact,
  setParticipantContactDetail,
  updateContactAccess,
  setSchoolDetail,
  updateParticipantDetail,
  setParticipantConsent,
  setParticipantCorrespondenceHistory,
  updateParticipantContactStatus,
  updateParentContactStatus,
  updateRecallingSentenceContactStatus,
  updateParentMP1ContactStatus,
  updateParticipantMP1ContactStatus,
  updateParticipantMP2ContactStatus,
  deleteParticipantConsentSurvey,
  deleteParentConsentSurvey,
  deleteRecallingSentenceTask,
  deleteParentMP1ConsentSurvey,
  deleteParticipantMP1ConsentSurvey,
  deleteParticipantMP2ConsentSurvey,
  getParticipantConsentWorkSheetDownload
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantContainer)
