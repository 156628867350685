import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import '@src/index.css'
import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import classNames from 'classnames'
import DownloadParticipantMp2 from '@components/download'
import UploadConsent from '@components/upload'
import UploadContactStatus from '@components/upload'
import ParticipantMPTwoFilterClearContainer from '@containers/filters/filterClear'
import ParticipantMPTwoFilterSaveContainer from '@containers/filters/filterSave'
import { Button, Divider, Tooltip, message, Select } from 'antd'
import { ProfileOutlined } from '@ant-design/icons'
import ModalComponent from '@components/modals'
import useModal from '@src/hooks/useModal'
import moment from 'moment'

const { Option } = Select

export default function ParticipantMPTwoListContainer (props) {
  const {
    loading,
    participantMPTwo,
    getParticipant,
    handleParticipantDetailsDisplay,
    setParticipantMPTwoConsent,
    setParticipantMPTwoCorrespondenceHistory,
    getParticipantMPTwoWorkSheetDownload
  } = props

  const dateRequired = ['Provided', 'Empty']
  const mp1CompletedSkippedStatus = ['True', 'False']
  const participantRecruitStatus = [
    'NOT_ELIGIBLE',
    'ELIGIBLE',
    'UNCONTACTABLE',
    'APPROACHED',
    'DID_NOT_RESPOND',
    'DECLINED_BY_PARENT',
    'DECLINED_BY_TEEN',
    'PARENT_CONSENT_UNKNOWN',
    'RECRUITED',
    'WITHDREW_BY_PARENT',
    'WITHDREW_BY_TEEN',
    'RECRUITED_THEN_UNCONTACTABLE'
  ]

  const schoolStatus = [
    'Still going to school',
    'Graduated from Year 12',
    "Left school but didn't graduate",
    'Empty'
  ]
  const schoolYearStatus = [
    'Year 9 or below',
    'Year 10',
    'Year 11',
    'Year 12',
    'IB',
    'Empty'
  ]

  const dt = useRef(null)
  const { visible, handleVisibility } = useModal()
  const [globalFilter, setGlobalFilter] = useState(null)
  const [participantMPTwoConsentSubmit, setParticipantMPTwoConsentSubmit] = useState(
    false
  )
  const [
    participantMPTwoCorrHistorySubmit,
    setParticipantMPTwoCorrHistorySubmit
  ] = useState(false)

  const [selectedParticipantRecruitStatus, setParticipantRecruitStatus] = useState(
    null
  )
  const [
    selectedMp1CompletedSkippedStatus,
    setSelectedMp1CompletedSkippedStatus
  ] = useState(null)

  const [selectedSchoolStatus, setSelectedSchoolStatus] = useState(null)
  const [selectedSchoolYearStatus, setSelectedSchoolYearStatus] = useState(null)
  const [selectedInvitationDate, setSelectedInvitationDate] = useState(null)
  const [selectedMp1InvitationDate, setSelectedMp1InvitationDate] = useState(null)
  const [inputPostDayInvitation, setInputPostDayInvitation] = useState(null)
  const [inputPostDayEmail, setInputPostDayEmail] = useState(null)
  const [inputPostDaySms, setInputPostDaySms] = useState(null)
  const [inputPostDayCall, setInputPostDayCall] = useState(null)
  const [selectedEmailStatus, setSelectedEmailStatus] = useState(null)
  const [selectedSmsStatus, setSelectedSmsStatus] = useState(null)
  const [selectedCallStatus, setSelectedCallStatus] = useState(null)
  const [selectedCompletionDate, setSelectedCompletionDate] = useState(null)
  const [inputPostMp1Reminder, setInputPostMp1Reminder] = useState(null)

  const [consentButtonClick, setConsentButtonClick] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [savedFilterData, setSavedDataFilter] = useState([])
  const [filters, setFilters] = useState(null)

  useEffect(() => {
    reInitialiseSelectData()
  }, [])

  const onUploadConsent = (file) => {
    setParticipantMPTwoConsent(file)
      .then(() => setParticipantMPTwoConsentSubmit(true))
      .catch(() => setParticipantMPTwoConsentSubmit(false))
    handleVisibility(false)
  }

  const onUploadContactStatus = (file) => {
    handleVisibility(false)
    setParticipantMPTwoCorrespondenceHistory(file)
      .then(() => setParticipantMPTwoCorrHistorySubmit(true))
      .catch(() => setParticipantMPTwoCorrHistorySubmit(false))
  }

  const showModal = (e) => {
    e.currentTarget.id === 'ParticipantConsent'
      ? setConsentButtonClick(true)
      : setConsentButtonClick(false)
    handleVisibility(true)
  }

  const onSaveFilter = (filterName) => {
    const savedFilter = JSON.parse(localStorage.getItem('participantMPTwoFilter'))
    const arr = []
    if (filters != null) {
      if (savedFilter != null && savedFilter.length > 0) {
        // Check the filters is already in the local Storage
        for (var i = 0; i < savedFilter.length; i++) {
          if (JSON.stringify(savedFilter[i]) === JSON.stringify(filters)) {
            break
          } else {
            const final_obj = { filterName: filterName, filters: filters }
            savedFilter.push(final_obj)
            localStorage.setItem(
              'participantMPTwoFilter',
              JSON.stringify(savedFilter)
            )
            reInitialiseSelectData()
            message.success('The choosen filter has been saved successfully!')
            break
          }
        }
      } else {
        // For the first time when no filter is set before
        const final_obj = { filterName: filterName, filters: filters }
        arr.push(final_obj)
        localStorage.setItem('participantMPTwoFilter', JSON.stringify(arr))
        reInitialiseSelectData()
        message.success('The choosen filter has been saved successfully!')
      }
    } else {
      message.warning('Please apply the filter on the table then save filter!')
    }
  }

  const onFilter = (e) => {
    setFilters(e.filters)
  }

  const reInitialiseSelectData = () => {
    const data = []
    const savedFilter = JSON.parse(localStorage.getItem('participantMPTwoFilter'))
    if (savedFilter != null) {
      for (var i = 0; i < savedFilter.length; i++) {
        data.push({
          key: i,
          label: savedFilter[i].filterName.filterName,
          value: JSON.stringify(savedFilter[i].filters)
        })
      }
    }
    setSavedDataFilter(data)
  }

  const onApplyFilter = (filter) => {
    if (filter !== null) {
      const selectedKeys = Object.entries(JSON.parse(filter))
      for (var i = 0; i < selectedKeys.length; i++) {
        if (selectedKeys[i][0] === 'schoolStatus') {
          setSelectedSchoolStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'teenRecruitmentStatus') {
          setParticipantRecruitStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'mp1CompletedSkipped') {
          setSelectedMp1CompletedSkippedStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'invitationDate') {
          setSelectedInvitationDate(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'mp1InvitationDate') {
          setSelectedMp1InvitationDate(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'completionDate') {
          setSelectedCompletionDate(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostInvitation') {
          setInputPostDayInvitation(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostEmail') {
          setInputPostDayEmail(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostSms') {
          setInputPostDaySms(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostCall') {
          setInputPostDayCall(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'schoolYear') {
          setSelectedSchoolYearStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'emailDate') {
          setSelectedEmailStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'callDate') {
          setSelectedCallStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'smsDate') {
          setSelectedSmsStatus(selectedKeys[i][1].value)
        } else if (selectedKeys[i][0] === 'daysPostMp1Remainder') {
          setInputPostMp1Reminder(selectedKeys[i][1].value)
        }
      }
      setFilters(JSON.parse(filter))
      message.success('The choosen filter has been applied successfully!')
    } else {
      setFilters(null)
    }
  }
  const onClearFilterAll = () => {
    localStorage.removeItem('participantMPTwoFilter')
    message.success('All filters have been removed successfully!')
    reInitialiseSelectData()
  }

  const onRemoveFilterOne = (choosenFilter) => {
    const allSavedFilter = JSON.parse(localStorage.getItem('participantMPTwoFilter'))
    for (var i = 0; i < allSavedFilter.length; i++) {
      if (JSON.stringify(allSavedFilter[i].filters) === choosenFilter) {
        allSavedFilter.splice(i, 1)
        localStorage.removeItem('participantMPTwoFilter')
        localStorage.setItem(
          'participantMPTwoFilter',
          JSON.stringify(allSavedFilter)
        )
        message.success('The choosen filter has been removed successfully!')
        break
      }
    }
    reInitialiseSelectData()
  }

  const onChangeFilter = (value) => {
    if (value !== undefined) {
      setSelectedFilter(value)
      onApplyFilter(value)
    } else {
      setSelectedFilter(null)
      clearAllFilterValues()
      onApplyFilter(null)
    }
  }
  const clearAllFilterValues = () => {
    setParticipantRecruitStatus(null)
    setSelectedMp1CompletedSkippedStatus(null)
    setSelectedSchoolStatus(null)
    setSelectedSchoolYearStatus(null)
    setSelectedInvitationDate(null)
    setSelectedMp1InvitationDate(null)
    setInputPostDayInvitation(null)
    setInputPostDayEmail(null)
    setInputPostDaySms(null)
    setInputPostDayCall(null)
    setInputPostMp1Reminder(null)
    setSelectedEmailStatus(null)
    setSelectedSmsStatus(null)
    setSelectedCallStatus(null)
    setSelectedCompletionDate(null)
    setGlobalFilter(null)
  }

  const clearAllFilterTable = () => {
    setSelectedFilter(null)
    clearAllFilterValues()
    onApplyFilter(null)
  }
  const renderHeader = () => {
    return (
      <div>
        Participant Measure Package - 2
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }} />
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }} />
          <div>
            <ParticipantMPTwoFilterSaveContainer
              name='participant-mptwo-consent'
              onSaveFilter={onSaveFilter}
              filters={filters}
            />
          </div>
          <p style={{ width: '25px' }} />
          <div>
            <Select
              showSearch
              style={{ width: 220 }}
              placeholder='Choose a Filter to Apply'
              optionFilterProp='savedFilterData'
              onChange={onChangeFilter}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {savedFilterData.map(function (option, i) {
                return (
                  <Option key={i} value={option.value}>
                    {option.label}
                  </Option>
                )
              })}
            </Select>
          </div>
          <p style={{ width: '25px' }} />
          <div>
            <ParticipantMPTwoFilterClearContainer
              name='participant-mptwo-consent'
              onRemoveFilterOne={onRemoveFilterOne}
              onClearFilterAll={onClearFilterAll}
            />
          </div>
          <p style={{ width: '25px' }} />
          <div>
            <Button
              onClick={clearAllFilterTable}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              Clear Filters
            </Button>
          </div>
          <p style={{ width: '25px' }} />
          <p style={{ width: '530px' }} />
          <div className='p-datatable-globalfilter-container'>
            <InputText
              type='search'
              onInput={(e) => {
                setGlobalFilter(e.target.value)
              }}
              placeholder='Global Search'
            />
          </div>
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <span>
            <p style={{ width: '1550px' }} />
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '25px' }} />
          <div>
            <Button
              id='ParticipantConsent'
              onClick={showModal}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              Upload Responses
            </Button>
            <ModalComponent
              width={800}
              visible={visible}
              handleVisibility={handleVisibility}
              title={
                consentButtonClick
                  ? "Participant's Measure Package 2 Responses Upload"
                  : 'Participant Measure Package 2 Correspondence History Upload'
              }
            >
              {consentButtonClick ? (
                <UploadConsent
                  name='participant-mptwo-consent'
                  labelType='PARTICIPANT'
                  onUploadClick={onUploadConsent}
                  loading={loading}
                />
              ) : (
                <UploadContactStatus
                  name='participant-mptwo-contact'
                  labelType='PARTICIPANT'
                  onUploadClick={onUploadContactStatus}
                  loading={loading}
                />
              )}
            </ModalComponent>
          </div>
          <p style={{ width: '25px' }} />
          <div>
            <Button
              id='ParticipantContact'
              onClick={showModal}
              style={{
                backgroundColor: '#008385',
                color: 'white',
                fontWeight: 'bold'
              }}
            >
              Upload Correspondence History
            </Button>
          </div>
          <p style={{ width: '25px' }} />
          <div>
            {participantMPTwo && (
              <DownloadParticipantMp2
                rawData={participantMPTwo}
                buttonLabel='Download Worksheet'
                componentId='participant-mptwo-consent'
                getDataDownload={getParticipantMPTwoWorkSheetDownload}
                loading={loading}
                filters={filters}
              />
            )}
          </div>
          <p style={{ width: '25px' }} />
          <p style={{ width: '25px' }} />
          <p style={{ width: '800px' }} />
        </div>
      </div>
    )
  }

  const operationBodyTemplate = (rowData) => {
    return (
      <span>
        <Tooltip title='Participant details'>
          <span>
            <ProfileOutlined
              onClick={() => {
                getParticipant(rowData.id)
                handleParticipantDetailsDisplay()
              }}
            />
          </span>
          <Divider type='vertical' />
        </Tooltip>
      </span>
    )
  }

  // School Status

  const statusSchoolBodyTemplate = (rowData) => {
    const schoolStatus = rowData.schoolStatus !== null ? rowData.schoolStatus : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-' +
            schoolStatus.toUpperCase().replace(/ /g, '_').replace("'", '')
        )}
      >
        {schoolStatus}
      </span>
    )
  }
  const renderSchoolStatusFilter = () => {
    return (
      <MultiSelect
        value={selectedSchoolStatus}
        options={schoolStatus}
        itemTemplate={statusSchoolTemplate}
        onChange={onStatusSchoolFilterChange}
        placeholder='Search By School Status'
        className='p-column-filter'
      />
    )
  }
  const statusSchoolTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-' + option.toUpperCase().replace(/ /g, '_').replace("'", '')
        )}
      >
        {option}
      </span>
    )
  }
  const onStatusSchoolFilterChange = (event) => {
    dt.current.filter(event.value, 'schoolStatus', 'in')
    setSelectedSchoolStatus(event.value)
  }

  const filterSchoolStatus = (value, filter) => {
    if (value === null && filter.includes('Empty')) {
      return true
    } else if (
      value === 'Still going to school' &&
      filter.includes('Still going to school')
    ) {
      return true
    } else if (
      value === 'Graduated from Year 12' &&
      filter.includes('Graduated from Year 12')
    ) {
      return true
    } else if (
      value === "Left school but didn't graduate" &&
      filter.includes("Left school but didn't graduate")
    ) {
      return true
    }
  }
  // End School Status

  // School Year Status

  const statusSchoolYearBodyTemplate = (rowData) => {
    const schoolYear =
      rowData.schoolYearLevel !== null ? rowData.schoolYearLevel : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-year-' + schoolYear.toUpperCase().replace(/ /g, '_')
        )}
      >
        {schoolYear}
      </span>
    )
  }
  const renderSchoolYearStatusFilter = () => {
    return (
      <MultiSelect
        value={selectedSchoolYearStatus}
        options={schoolYearStatus}
        itemTemplate={statusSchoolYearItemTemplate}
        onChange={onStatusSchoolYearFilterChange}
        placeholder='Search By School Year'
        className='p-column-filter'
      />
    )
  }
  const statusSchoolYearItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'school-status-year-' + option.toUpperCase().replace(/ /g, '_')
        )}
      >
        {option}
      </span>
    )
  }
  const onStatusSchoolYearFilterChange = (event) => {
    dt.current.filter(event.value, 'schoolYearLevel', 'custom')
    setSelectedSchoolYearStatus(event.value)
  }

  const filterSchoolYear = (value, filter) => {
    if (value === null && filter.includes('Empty')) {
      return true
    } else if (value === 'Year 10' && filter.includes('Year 10')) {
      return true
    } else if (value === 'Year 9 or below' && filter.includes('Year 9 or below')) {
      return true
    } else if (value === 'Year 11' && filter.includes('Year 11')) {
      return true
    } else if (value === 'Year 12' && filter.includes('Year 12')) {
      return true
    } else if (value === 'IB' && filter.includes('IB')) {
      return true
    }
  }
  // End School Year Status

  //  Email Status
  const statusEmailBodyTemplate = (rowData) => {
    const emailSent =
      rowData.emailDate !== null && rowData.emailDate !== undefined
        ? moment(rowData.emailDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + emailSent)}>
        {emailSent}
      </span>
    )
  }
  const renderEmailStatusFilter = () => {
    return (
      <Dropdown
        value={selectedEmailStatus}
        options={dateRequired}
        onChange={onStatusEmailFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder='Search By Email Status'
        className='p-column-filter'
      />
    )
  }
  const statusItemTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'status-' + option)}>
        {option}
      </span>
    )
  }
  const onStatusEmailFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'emailDate', 'custom')
    } else {
      dt.current.filter(event.value, 'emailDate', 'custom')
    }
    setSelectedEmailStatus(event.value)
  }
  const filterEmailDate = (value) => {
    if (selectedEmailStatus === 'Provided') {
      return value != null
    } else if (selectedEmailStatus === 'Empty') {
      return value == null
    }
  }
  // End Email Status

  //  Sms Status
  const statusSmsBodyTemplate = (rowData) => {
    const smsSent =
      rowData.smsDate !== null && rowData.smsDate !== undefined
        ? moment(rowData.smsDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + smsSent)}>
        {smsSent}
      </span>
    )
  }
  const renderSmsStatusFilter = () => {
    return (
      <Dropdown
        value={selectedSmsStatus}
        options={dateRequired}
        onChange={onStatusSmsFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder='Search By SMS Status'
        className='p-column-filter'
      />
    )
  }
  const onStatusSmsFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'smsDate', 'custom')
    } else {
      dt.current.filter(event.value, 'smsDate', 'custom')
    }
    setSelectedSmsStatus(event.value)
  }
  const filterSmsDate = (value) => {
    if (selectedSmsStatus === 'Provided') {
      return value != null
    } else if (selectedSmsStatus === 'Empty') {
      return value == null
    }
  }
  // End SMS Status

  //  Call Status
  const statusCallBodyTemplate = (rowData) => {
    const callMade =
      rowData.callDate !== null && rowData.callDate !== undefined
        ? moment(rowData.callDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + callMade)}>
        {callMade}
      </span>
    )
  }

  const renderCallStatusFilter = () => {
    return (
      <Dropdown
        value={selectedCallStatus}
        options={dateRequired}
        onChange={onStatusCallFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder='Search By Call Status'
        className='p-column-filter'
      />
    )
  }
  const onStatusCallFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'callDate', 'custom')
    } else {
      dt.current.filter(event.value, 'callDate', 'custom')
    }
    setSelectedCallStatus(event.value)
  }

  const filterCallMadeDate = (value) => {
    if (selectedCallStatus === 'Provided') {
      return value != null
    } else if (selectedCallStatus === 'Empty') {
      return value == null
    }
  }
  // End SMS Status

  // Start Participant Recruitment Status

  const statusBodyTeenRecruitTemplate = (rowData) => {
    const participantRecruitmentStatus =
      rowData.participantRecruitmentStatus !== null
        ? rowData.participantRecruitmentStatus
        : '--'
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParticipantRecruitmentStatus-' + participantRecruitmentStatus
        )}
      >
        {participantRecruitmentStatus}
      </span>
    )
  }

  // Mp1 Completed/Skipped Template
  const statusBodyMp1CompletedSkippedTemplate = (rowData) => {
    const mp1CompletedSkipped =
      rowData.mp1CompletedSkipped !== null ? rowData.mp1CompletedSkipped : '--'
    return (
      <span
        className={classNames(
          'mp1-skipped-completed',
          'status-' + mp1CompletedSkipped
        )}
      >
        {mp1CompletedSkipped}
      </span>
    )
  }

  const renderStatusTeenRecruitFilter = () => {
    return (
      <Dropdown
        value={selectedParticipantRecruitStatus}
        options={participantRecruitStatus}
        onChange={onParticipantRecruitStatusFilterChange}
        itemTemplate={statusParticipantRecruitItemTemplate}
        showClear
        placeholder='Search Status'
        className='p-column-filter'
      />
    )
  }

  const renderStatusMp1CompletedSkippedFilter = () => {
    return (
      <Dropdown
        value={selectedMp1CompletedSkippedStatus}
        options={mp1CompletedSkippedStatus}
        onChange={onMp1CompletedSkippedFilterChange}
        itemTemplate={statusMp1CompletedSkippedTemplate}
        showClear
        placeholder='Filter Mp1 Completed/Reminded'
        className='p-column-filter'
      />
    )
  }

  const statusParticipantRecruitItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'participant-badge',
          'ParticipantRecruitmentStatus-' + option
        )}
      >
        {option}
      </span>
    )
  }

  const statusMp1CompletedSkippedTemplate = (option) => {
    return (
      <span className={classNames('mp1-skipped-completed', 'status-' + option)}>
        {option}
      </span>
    )
  }

  const onParticipantRecruitStatusFilterChange = (event) => {
    dt.current.filter(event.value, 'participantRecruitmentStatus', 'equals')
    setParticipantRecruitStatus(event.value)
  }

  const onMp1CompletedSkippedFilterChange = (event) => {
    dt.current.filter(event.value, 'mp1CompletedSkipped', 'equals')
    setSelectedMp1CompletedSkippedStatus(event.value)
  }

  /// end Parent Recruitment Status
  //  CompletionDate Status
  const statusCompletionBodyTemplate = (rowData) => {
    const compDate =
      rowData.completionDate !== null && rowData.completionDate !== undefined
        ? moment(rowData.completionDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + compDate)}>
        {compDate}
      </span>
    )
  }
  const renderCompletionDateFilter = () => {
    return (
      <Dropdown
        value={selectedCompletionDate}
        options={dateRequired}
        onChange={onCompletionDateFilterChange}
        itemTemplate={statusItemTemplate}
        showClear
        placeholder='Search CompletionDate'
        className='p-column-filter'
      />
    )
  }


  const onCompletionDateFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'completionDate', 'custom')
    } else {
      dt.current.filter(event.value, 'completionDate', 'custom')
    }
    setSelectedCompletionDate(event.value)
  }

  const filterCompleteDate = (value) => {
    if (selectedCompletionDate === 'Provided') {
      return value != null
    } else if (selectedCompletionDate === 'Empty') {
      return value == null
    }
  }
  ///
  //  InvitationDate Status
  const statusInvitationBodyTemplate = (rowData) => {
    const invDate =
      rowData.invitationDate !== null && rowData.invitationDate !== undefined
        ? moment(rowData.invitationDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + invDate)}>
        {invDate}
      </span>
    )
  }

  //  MP1 InvitationDate Status
  const statusMp1InvitationBodyTemplate = (rowData) => {
    const invDate =
      rowData.mp1InvitationDate !== null && rowData.mp1InvitationDate !== undefined
        ? moment(rowData.mp1InvitationDate).format('DD-MM-YYYY')
        : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + invDate)}>
        {invDate}
      </span>
    )
  }

  const renderInviteDateStatusFilter = () => {
    return (
      <Dropdown
        value={selectedInvitationDate}
        options={dateRequired}
        onChange={onInvitationDateFilterChange}
        itemTemplate={actionInviteTemplate}
        showClear
        placeholder='Search Invitation Sent'
        className='p-column-filter'
      />
    )
  }

  const renderMp1InviteDateStatusFilter = () => {
    return (
      <Dropdown
        value={selectedMp1InvitationDate}
        options={dateRequired}
        onChange={onMp1InvitationDateFilterChange}
        itemTemplate={actionInviteTemplate}
        showClear
        placeholder='Search Invitation Sent'
        className='p-column-filter'
      />
    )
  }

  const actionInviteTemplate = (option) => {
    return (
      <span className={classNames('participant-badge', 'p-status-' + option)}>
        {option}
      </span>
    )
  }

  const onInvitationDateFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'invitationDate', 'custom')
    } else {
      dt.current.filter(event.value, 'invitationDate', 'custom')
    }
    setSelectedInvitationDate(event.value)
  }

  const onMp1InvitationDateFilterChange = (event) => {
    if (event.value === 'Provided') {
      dt.current.filter(event.value, 'mp1InvitationDate', 'custom')
    } else {
      dt.current.filter(event.value, 'mp1InvitationDate', 'custom')
    }
    setSelectedMp1InvitationDate(event.value)
  }

  const filterInviteDate = (value) => {
    if (selectedInvitationDate === 'Provided') {
      return value != null
    } else if (selectedInvitationDate === 'Empty') {
      return value == null
    }
  }

  const filterMp1InviteDate = (value) => {
    if (selectedMp1InvitationDate === 'Provided') {
      return value != null
    } else if (selectedMp1InvitationDate === 'Empty') {
      return value == null
    }
  }

  ///

  //  DaysPost Invitation Status
  const daysPostInvitationBodyTemplate = (rowData) => {
    const daysPostInv =
      rowData.daysPostInvitation !== null ? rowData.daysPostInvitation : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostInv)}>
        {daysPostInv}
      </span>
    )
  }

  const renderDaysPostInviteFilter = () => {
    return (
      <InputText
        type='number'
        style={{ width: '90px' }}
        onChange={onChangeDaysPostInterval}
        value={inputPostDayInvitation !== null ? inputPostDayInvitation : '>'}
        placeholder='Search By: > '
      />
    )
  }
  const onChangeDaysPostInterval = (e) => {
    dt.current.filter(e.target.value, 'daysPostInvitation', 'gte')
    setInputPostDayInvitation(e.target.value)
  }
  ///
  //  DaysPost Email Date
  const daysPostEmailBodyTemplate = (rowData) => {
    const daysPostEmail = rowData.daysPostEmail !== null ? rowData.daysPostEmail : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostEmail)}>
        {daysPostEmail}
      </span>
    )
  }

  const renderDaysPostEmailFilter = () => {
    return (
      <InputText
        type='number'
        style={{ width: '90px' }}
        onChange={onChangeDaysPostEmail}
        value={inputPostDayEmail !== null ? inputPostDayEmail : '>'}
        placeholder='Search By: > '
      />
    )
  }
  const onChangeDaysPostEmail = (e) => {
    dt.current.filter(e.target.value, 'daysPostEmail', 'gte')
    setInputPostDayEmail(e.target.value)
  }
  ///
  //  DaysPost SMS Date
  const daysPostSmsBodyTemplate = (rowData) => {
    const daysPostSms = rowData.daysPostSms !== null ? rowData.daysPostSms : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostSms)}>
        {daysPostSms}
      </span>
    )
  }

  const renderDaysPostSmsFilter = () => {
    return (
      <InputText
        type='number'
        style={{ width: '90px' }}
        onChange={onChangeDaysPostSms}
        value={inputPostDaySms !== null ? inputPostDaySms : '>'}
        placeholder='Search By: > '
      />
    )
  }
  const onChangeDaysPostSms = (e) => {
    dt.current.filter(e.target.value, 'daysPostSms', 'gte')
    setInputPostDaySms(e.target.value)
  }
  ///
  //  DaysPost Call Date
  const daysPostCallBodyTemplate = (rowData) => {
    const daysPostCall = rowData.daysPostCall !== null ? rowData.daysPostCall : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostCall)}>
        {daysPostCall}
      </span>
    )
  }

  //  DaysPost Call Date
  const daysPostMp1ReminderTemplate = (rowData) => {
    const daysPostMp1Remainder = rowData.daysPostMp1Remainder !== null ? rowData.daysPostMp1Remainder : '--'
    return (
      <span className={classNames('participant-badge', 'status-' + daysPostMp1Remainder)}>
        {daysPostMp1Remainder}
      </span>
    )
  }

  const renderDaysPostCallFilter = () => {
    return (
      <InputText
        type='number'
        style={{ width: '90px' }}
        onChange={onChangeDaysPostCall}
        value={inputPostDayCall !== null ? inputPostDayCall : '>'}
        placeholder='Search By: > '
      />
    )
  }

  const renderDaysPostMp1ReminderFilter = () => {
    return (
      <InputText
        type='number'
        style={{ width: '90px' }}
        onChange={onChangeMp1ReminderCall}
        value={inputPostMp1Reminder !== null ? inputPostMp1Reminder : '>'}
        placeholder='Search By: > '
      />
    )
  }
  const onChangeDaysPostCall = (e) => {
    dt.current.filter(e.target.value, 'daysPostCall', 'gte')
    setInputPostDayCall(e.target.value)
  }

  const onChangeMp1ReminderCall = (e) => {
    dt.current.filter(e.target.value, 'daysPostMp1Remainder', 'gte')
    setInputPostMp1Reminder(e.target.value)
  }

  ///
  const onTableRowClick = (rowData) => {
    getParticipant(rowData.data.id)
    handleParticipantDetailsDisplay()
  }

  const header = renderHeader()
  const statusSchoolFilter = renderSchoolStatusFilter()
  const statusSchoolYearStatusFilter = renderSchoolYearStatusFilter()
  const statusTeenRecruitFilter = renderStatusTeenRecruitFilter()
  const statusMp1CompletedSkippedFilter = renderStatusMp1CompletedSkippedFilter()
  const statusCompletionDateFilter = renderCompletionDateFilter()
  const statusEmailFilter = renderEmailStatusFilter()
  const statusSmsFilter = renderSmsStatusFilter()
  const statusCallFilter = renderCallStatusFilter()
  const statusInviteDateFilter = renderInviteDateStatusFilter()
  const statusMp1InviteDateFilter = renderMp1InviteDateStatusFilter()
  const daysPostInviteFilter = renderDaysPostInviteFilter()
  const daysPostEmailFilter = renderDaysPostEmailFilter()
  const daysPostSmsFilter = renderDaysPostSmsFilter()
  const daysPostCallFilter = renderDaysPostCallFilter()
  const daysPostMp1ReminderFilter = renderDaysPostMp1ReminderFilter()
  const footer = renderFooter()

  return (
    <div
      className='datatable-doc-demo'
      style={{
        width: '2600px'
      }}
    >
      <DataTable
        ref={dt}
        value={participantMPTwo}
        header={header}
        footer={footer}
        filters={filters}
        onFilter={onFilter}
        responsive
        className='p-datatable-customers'
        dataKey='id'
        rowHover
        globalFilter={globalFilter}
        onRowClick={onTableRowClick}
        paginator
        rows={10}
        emptyMessage='No Participant Measure Package 2 found'
        currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
        paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        rowsPerPageOptions={[10, 100, 200, 300, participantMPTwo?.length]}
      >
        <Column
          field='participantId'
          header='Participant ID'
          headerStyle={{ width: '80px' }}
          sortable
          filter
          filterPlaceholder='Search by Participant ID'
        />
        <Column
          field='firstName'
          header='First Name'
          headerStyle={{ width: '80px' }}
          sortable
          filter
          filterPlaceholder='Search by First Name'
        />
        <Column
          field='lastName'
          header='Last Name'
          headerStyle={{ width: '80px' }}
          sortable
          filter
          filterPlaceholder='Search by Last Name'
        />
        <Column
          field='participantRecruitmentStatus'
          header='Teen Recruitment Status'
          headerStyle={{ width: '150px' }}
          body={statusBodyTeenRecruitTemplate}
          sortable
          filter
          filterElement={statusTeenRecruitFilter}
          filterPlaceholder='Search by Parent Recruitment Status'
        />
        <Column
          field='schoolStatus'
          header='School Status'
          headerStyle={{ width: '120px' }}
          body={statusSchoolBodyTemplate}
          sortable
          filter
          filterMatchMode='custom'
          filterFunction={filterSchoolStatus}
          filterElement={statusSchoolFilter}
          filterPlaceholder='Search by School Status'
        />
        <Column
          field='schoolYearLevel'
          header='School Year Level'
          headerStyle={{ width: '120px' }}
          body={statusSchoolYearBodyTemplate}
          sortable
          filter
          filterMatchMode='custom'
          filterFunction={filterSchoolYear}
          filterElement={statusSchoolYearStatusFilter}
          filterPlaceholder='Search by School Year Level'
        />
        <Column
          field='mp1CompletedSkipped'
          header='Mp1 Completed/Reminded'
          headerStyle={{ width: '120px' }}
          body={statusBodyMp1CompletedSkippedTemplate}
          sortable
          filter
          filterElement={statusMp1CompletedSkippedFilter}
          filterPlaceholder='Search by Parent Recruitment Status'
        />
        <Column
          field='daysPostMp1Remainder'
          header='Days post Mp1 remainder call'
          headerStyle={{ width: '120px' }}
          body={daysPostMp1ReminderTemplate}
          sortable
          filter
          filterElement={daysPostMp1ReminderFilter}
          filterPlaceholder='Days Post Mp1 reminder'
        />
        <Column
          field='mp1InvitationDate'
          header='MP1 Invitation Sent'
          headerStyle={{ width: '80px' }}
          body={statusMp1InvitationBodyTemplate}
          sortable
          filter
          filterFunction={filterMp1InviteDate}
          filterElement={statusMp1InviteDateFilter}
          filterPlaceholder='Search by Invitation Date'
        />
        <Column
          field='invitationDate'
          header='Invitation Sent'
          headerStyle={{ width: '80px' }}
          body={statusInvitationBodyTemplate}
          sortable
          filter
          filterFunction={filterInviteDate}
          filterElement={statusInviteDateFilter}
          filterPlaceholder='Search by Invitation Date'
        />
        <Column
          field='daysPostInvitation'
          header='Days Post Invitation'
          headerStyle={{ width: '80px' }}
          body={daysPostInvitationBodyTemplate}
          sortable
          filter
          filterElement={daysPostInviteFilter}
          filterPlaceholder='> Search'
        />
        <Column
          field='emailDate'
          header='Reminder Email Sent'
          headerStyle={{ width: '80px' }}
          body={statusEmailBodyTemplate}
          sortable
          filter
          filterFunction={filterEmailDate}
          filterElement={statusEmailFilter}
          filterPlaceholder='Search by Email Sent'
        />
        <Column
          field='daysPostEmail'
          header='Days Post Email'
          headerStyle={{ width: '60px' }}
          body={daysPostEmailBodyTemplate}
          sortable
          filter
          filterElement={daysPostEmailFilter}
          filterPlaceholder='> Search'
        />
        <Column
          field='smsDate'
          header='Reminder SMS Sent'
          headerStyle={{ width: '100px' }}
          body={statusSmsBodyTemplate}
          sortable
          filter
          filterFunction={filterSmsDate}
          filterElement={statusSmsFilter}
          filterPlaceholder='Search by SMS Sent'
        />
        <Column
          field='daysPostSms'
          header='Days Post Sms'
          headerStyle={{ width: '60px' }}
          body={daysPostSmsBodyTemplate}
          sortable
          filter
          filterElement={daysPostSmsFilter}
          filterPlaceholder='> Search'
        />
        <Column
          field='callDate'
          header='Reminder Call Made'
          headerStyle={{ width: '100px' }}
          body={statusCallBodyTemplate}
          sortable
          filter
          filterFunction={filterCallMadeDate}
          filterElement={statusCallFilter}
          filterPlaceholder='Search by Call Made'
        />
        <Column
          field='daysPostCall'
          header='Days Post Call'
          headerStyle={{ width: '60px' }}
          body={daysPostCallBodyTemplate}
          sortable
          filter
          filterElement={daysPostCallFilter}
          filterPlaceholder='> Search'
        />
        <Column
          field='completionDate'
          header='Completion Date'
          headerStyle={{ width: '80px' }}
          body={statusCompletionBodyTemplate}
          sortable
          filter
          filterFunction={filterCompleteDate}
          filterElement={statusCompletionDateFilter}
          filterPlaceholder='Search by Completion Date'
        />
        <Column
          body={operationBodyTemplate}
          headerStyle={{ width: '8em', textAlign: 'center' }}
          header='Operations'
          bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
        />
      </DataTable>
    </div>
  )
}
